import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";

Vue.use(Router);
let rolesList = store.getters.rolesList;

export default new Router({
  mode: "history",
  routes: [
    // Landing
    {
      path: "/",
      redirect: "/",
      component: () => import("@/view/layout/LayoutLanding"),
      children: [
        {
          path: "/",
          name: "Landing",
          component: () => import("@/view/pages/landing/Index.vue")
        },
        {
          path: "/sender",
          name: "Sender",
          component: () => import("@/view/pages/landing/Sender.vue")
        },
        {
          path: "/transporter",
          name: "Transporter",
          component: () => import("@/view/pages/landing/Transporter.vue")
        },
        {
          path: "/contact",
          name: "Contact",
          component: () => import("@/view/pages/landing/Contact.vue")
        },
        {
          path: "/legal/privacy",
          name: "Privacy Policy",
          component: () =>
            import("@/view/pages/landing/legal/privacy/Index.vue")
        },
        {
          path: "/legal/cookies",
          name: "Cookies Policy",
          component: () =>
            import("@/view/pages/landing/legal/cookies/Index.vue")
        },
        {
          path: "/legal/terms",
          name: "Terms and Conditions",
          component: () => import("@/view/pages/landing/legal/terms/Index.vue")
        }
      ]
    },
    // Price Search
    {
      path: "/pricesearch/",
      component: () => import("@/view/layout/LayoutPriceSearch"),
      children: [
        { path: "", redirect: "/pricesearch/step1" },
        {
          path: "step1",
          name: "Price Search Query",
          component: () => import("@/view/pages/pricesearch/Step1.vue")
        },
        {
          path: "step2",
          name: "Price Search Pick",
          component: () => import("@/view/pages/pricesearch/Step2.vue")
        },
        {
          path: "step3/:routeCacheIndex/:transporterCacheIndex",
          name: "Price Search Extra Data",
          component: () => import("@/view/pages/pricesearch/Step3.vue")
        },
        {
          path: "purchaseOK/:shipmentID",
          name: "Price Search Purchased OK",
          component: () => import("@/view/pages/pricesearch/PurchaseOK.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "purchaseKO",
          name: "Price Search Purchased KO",
          component: () => import("@/view/pages/pricesearch/PurchaseKO.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        }
      ]
    },
    // Login & Register
    {
      path: "/",
      component: () => import("@/view/layout/LayoutLogin"),
      children: [
        {
          name: "Login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "Forgot your password",
          path: "/forgotpassword",
          component: () => import("@/view/pages/auth/ForgotPassword")
        },
        {
          name: "Reset your password",
          path: "/resetpassword/:token",
          component: () => import("@/view/pages/auth/ResetPassword")
        },
        {
          name: "Register",
          path: "/register/:type",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "Register OK",
          path: "/registerOK",
          component: () => import("@/view/pages/auth/RegisterOK")
        },
        {
          name: "Register KO",
          path: "/registerKO",
          component: () => import("@/view/pages/auth/RegisterKO")
        }
      ]
    },
    // Private pages
    {
      path: "/manager/",
      component: () => import("@/view/layout/LayoutManager"),
      meta: {
        requiresAuth: true
      },
      children: [
        // 1. Home
        { path: "", redirect: "/manager/home" },
        {
          path: "home",
          name: "Home",
          component: () => import("@/view/pages/manager/home/Home.vue")
        },
        // 2. My profile
        {
          path: "myprofile",
          name: "My Profile",
          component: () =>
            import("@/view/pages/manager/myprofile/MyProfile.vue")
        },
        {
          path: "myownprofile",
          name: "My Own Profile",
          component: () =>
            import("@/view/pages/manager/myprofile/MyOwnProfile.vue")
        },

        // 3. Companies
        // 3.a. For Superadmin: two alias for the same views
        {
          path: "senders",
          name: "Senders",
          component: () =>
            import("@/view/pages/manager/companies/superadmin/Index.vue"),
          props: { companyType: store.getters.companyTypesList.ID_SENDER },
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "senders/edit/:id",
          name: "Edit Sender",
          component: () =>
            import("@/view/pages/manager/companies/superadmin/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "transporters",
          name: "Transporters",
          component: () =>
            import("@/view/pages/manager/companies/superadmin/Index.vue"),
          props: {
            companyType: store.getters.companyTypesList.ID_TRANSPORTER
          },
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "transporters/edit/:id",
          name: "Edit Transporter",
          component: () =>
            import("@/view/pages/manager/companies/superadmin/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        // 3.b. For TransporterAdmin, SenderAdmin & SenderEmployee over his own company -> embedded in MyProfile
        // EXCEPT "My Subscription" (which is just for  TransporterAdmin & SenderAdmin)
        {
          path: "mysubscription",
          name: "My Subscription",
          component: () =>
            import(
              "@/view/pages/manager/companies/admin/MyCompanySubscription.vue"
            ),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_TRANSPORTER_ADMIN
            ]
          }
        },
        // 3.c. For TransporterAdmin viewing one of "My Senders"
        {
          path: "mysenders/show/:id",
          name: "My Sender",
          component: () =>
            import("@/view/pages/manager/companies/transporter/Show.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },

        // 4. Users
        // 4.a. For Superadmin: all users
        {
          path: "users",
          name: "Users",
          component: () =>
            import("@/view/pages/manager/users/superadmin/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "users/edit/:id",
          name: "Edit User",
          component: () =>
            import("@/view/pages/manager/users/superadmin/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },

        // 4.b. For TransporterAdmin: his drivers
        {
          path: "drivers",
          name: "Drivers",
          component: () =>
            import("@/view/pages/manager/users/transporter/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "drivers/new",
          name: "New Driver",
          component: () =>
            import("@/view/pages/manager/users/transporter/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "drivers/edit/:id",
          name: "Edit Driver",
          component: () =>
            import("@/view/pages/manager/users/transporter/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },

        // 4.c. For SenderAdmin: his employees
        {
          path: "employees/new",
          name: "New Employee",
          component: () =>
            import("@/view/pages/manager/users/senderadmin/employees/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SENDER_ADMIN]
          }
        },
        {
          path: "employees/edit/:id",
          name: "Edit Employee",
          component: () =>
            import("@/view/pages/manager/users/senderadmin/employees/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SENDER_ADMIN]
          }
        },

        // 4.d. For both SenderAdmin & SenderEmployee: their observers
        {
          path: "observers/new",
          name: "New Observer",
          component: () =>
            import("@/view/pages/manager/users/sender/observers/Edit.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "observers/edit/:id",
          name: "Edit Observer",
          component: () =>
            import("@/view/pages/manager/users/sender/observers/Edit.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },

        // 5. Vehicles
        // 5.a. For Superadmin -> VehicleModels & VehicleTypes
        {
          path: "vehicles",
          name: "Vehicles",
          component: () =>
            import("@/view/pages/manager/vehicles/superadmin/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "vehicles/new",
          name: "New Vehicle",
          component: () =>
            import("@/view/pages/manager/vehicles/superadmin/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "vehicles/edit/:id",
          name: "Edit Vehicle",
          component: () =>
            import("@/view/pages/manager/vehicles/superadmin/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "vehicles/types",
          name: "Vehicle Types",
          component: () =>
            import("@/view/pages/manager/vehicles/superadmin/types/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "vehicles/types/new",
          name: "New Vehicle Type",
          component: () =>
            import("@/view/pages/manager/vehicles/superadmin/types/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "vehicles/types/edit/:id",
          name: "Edit Vehicle Type",
          component: () =>
            import("@/view/pages/manager/vehicles/superadmin/types/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },

        // 5.b. For TransporterAdmin -> his Trucks & TruckCategories
        {
          path: "trucks",
          name: "Trucks",
          component: () =>
            import("@/view/pages/manager/vehicles/transporter/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "trucks/new",
          name: "New Truck",
          component: () =>
            import("@/view/pages/manager/vehicles/transporter/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "trucks/edit/:id",
          name: "Edit Truck",
          component: () =>
            import("@/view/pages/manager/vehicles/transporter/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "trucks/categories",
          name: "Truck Categories",
          component: () =>
            import(
              "@/view/pages/manager/vehicles/transporter/categories/Index.vue"
            ),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "trucks/categories/new",
          name: "New Truck Category",
          component: () =>
            import(
              "@/view/pages/manager/vehicles/transporter/categories/Edit.vue"
            ),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "trucks/categories/edit/:id",
          name: "Edit Truck Category",
          component: () =>
            import(
              "@/view/pages/manager/vehicles/transporter/categories/Edit.vue"
            ),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        // 5.c. For SenderAdmin & SenderEmployee ONLY PREMIUM -> his vehicles
        {
          path: "myvehicles",
          name: "My Vehicles",
          component: () =>
            import("@/view/pages/manager/vehicles/sender/Index.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "myvehicles/new",
          name: "New My Vehicle",
          component: () =>
            import("@/view/pages/manager/vehicles/sender/New.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "myvehicles/edit/:id",
          name: "My Vehicle Profile",
          component: () =>
            import("@/view/pages/manager/vehicles/sender/ProfileTabs.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "myvehicles/excel",
          name: "Excel My Vehicles",
          component: () =>
            import("@/view/pages/manager/vehicles/sender/Excel.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },

        // 6. Invoice Centers (just PREMIUM Senders)
        {
          path: "invoicecenters",
          name: "Invoice Centers",
          component: () => import("@/view/pages/manager/centers/Index.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "invoicecenters/new",
          name: "New Invoice Center",
          component: () => import("@/view/pages/manager/centers/Edit.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "invoicecenters/edit/:id",
          name: "Edit Invoice Center",
          component: () => import("@/view/pages/manager/centers/Edit.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },

        // 7. Frequent Addresses (just PREMIUM Senders)
        {
          path: "addresses",
          name: "Frequent Addresses",
          component: () =>
            import("@/view/pages/manager/addresses/MyAddressesTabs.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "addresses/new",
          name: "New Address",
          component: () =>
            import("@/view/pages/manager/addresses/frequent/Edit.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "addresses/edit/:id",
          name: "Edit Address",
          component: () =>
            import("@/view/pages/manager/addresses/frequent/Edit.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },

        // 8. Tender Routes (just PREMIUM Senders)
        {
          path: "tenderroutes/new",
          name: "New Tender Route",
          component: () =>
            import("@/view/pages/manager/addresses/tender/New.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },
        {
          path: "tenderroutes/edit/:id",
          name: "Edit Tender Route",
          component: () =>
            import("@/view/pages/manager/addresses/tender/Edit.vue"),
          meta: {
            requiresPremiumSubscription: true,
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE
            ]
          }
        },

        // 9. Services (just TransporterAdmin)
        {
          path: "services",
          name: "Services",
          component: () => import("@/view/pages/manager/services/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "services/new",
          name: "New Service",
          component: () => import("@/view/pages/manager/services/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "services/edit/:id",
          name: "Edit Service",
          component: () => import("@/view/pages/manager/services/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },

        // 10. Fares (just TransporterAdmin)
        {
          path: "fares",
          name: "Fares",
          component: () => import("@/view/pages/manager/fares/FaresTabs.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        // 10.1. Standard
        {
          path: "fares/standard/new",
          name: "New Standard Fare",
          component: () =>
            import("@/view/pages/manager/fares/standard/New.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "fares/standard/edit/:id",
          name: "Standard Fare Profile",
          component: () =>
            import("@/view/pages/manager/fares/standard/ProfileTabs.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "fares/standard/excel",
          name: "Excel Standard Fares",
          component: () =>
            import("@/view/pages/manager/fares/standard/Excel.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        // 10.2. Tender
        {
          path: "fares/tender/fromsender/:senderid",
          name: "TenderRoute Fares from Sender",
          component: () =>
            import("@/view/pages/manager/fares/tender/IndexFromSender.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "fares/tender/fromsender/:senderid/:fareid",
          name: "TenderRoute Fare Profile",
          component: () =>
            import("@/view/pages/manager/fares/tender/ProfileTabs.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        // 10.3. P2P TO-DO TBD

        // 11. My shipments at offer phase (for TransporterAdmin, SenderAdmin & SenderEmployee)
        {
          path: "offers",
          name: "Offers",
          component: () =>
            import("@/view/pages/manager/shipments/offer/OffersTabs.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE,
              rolesList.ID_TRANSPORTER_ADMIN
            ]
          }
        },
        // 11.1. Standard
        {
          path: "offers/standard/edit/:id",
          name: "Standard Offer Profile",
          component: () =>
            import(
              "@/view/pages/manager/shipments/offer/standard/ProfileTabs.vue"
            ),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE,
              rolesList.ID_TRANSPORTER_ADMIN
            ]
          }
        },
        // 11.2. Special TO-DO TBD
        // 11.3. Tender TO-DO TBD

        // 12. My shipments after offer phase a.k.a. tracking
        // (for TransporterAdmin, SenderAdmin, SenderEmployee & Observer)
        {
          path: "shipments",
          name: "Shipments",
          component: () =>
            import("@/view/pages/manager/shipments/tracking/Index.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE,
              rolesList.ID_TRANSPORTER_ADMIN,
              rolesList.ID_OBSERVER
            ]
          }
        },
        {
          path: "shipments/edit/:id",
          name: "Shipment Profile",
          component: () =>
            import("@/view/pages/manager/shipments/tracking/ProfileTabs.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE,
              rolesList.ID_TRANSPORTER_ADMIN,
              rolesList.ID_OBSERVER
            ]
          }
        },
        // 12.1. Modify incidents on a Shipment after offer phase (just for TransporterAdmin)
        {
          path: "shipments/edit/:id/incidents/new",
          name: "Shipment Profile: New Incident",
          component: () =>
            import(
              "@/view/pages/manager/shipments/tracking/incidents/Edit.vue"
            ),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "shipments/edit/:id/incidents/edit/:idI",
          name: "Shipment Profile: Edit Incident",
          component: () =>
            import(
              "@/view/pages/manager/shipments/tracking/incidents/Edit.vue"
            ),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },

        // 13. Full shipments search (just Superadmin)
        {
          path: "shipmentsall",
          name: "Shipments All",
          component: () =>
            import("@/view/pages/manager/shipments/superadmin/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },

        // 14. My chats (for TransporterAdmin, SenderAdmin, SenderEmployee & Observer)
        {
          path: "chats",
          name: "Chats",
          component: () => import("@/view/pages/manager/chats/ChatsTabs.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE,
              rolesList.ID_TRANSPORTER_ADMIN,
              rolesList.ID_OBSERVER
            ]
          }
        },

        // 15. My unfinished incidents (for TransporterAdmin, SenderAdmin, SenderEmployee & Observer)
        {
          path: "incidents",
          name: "Incidents",
          component: () => import("@/view/pages/manager/incidents/Index.vue"),
          meta: {
            requiresRoleID: [
              rolesList.ID_SENDER_ADMIN,
              rolesList.ID_SENDER_EMPLOYEE,
              rolesList.ID_TRANSPORTER_ADMIN,
              rolesList.ID_OBSERVER
            ]
          }
        },

        // 16. My incidents types (just TransporterAdmin)
        {
          path: "incidents/types",
          name: "Incident Types",
          component: () =>
            import("@/view/pages/manager/incidents/types/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "incidents/types/new",
          name: "New Incident Type",
          component: () =>
            import("@/view/pages/manager/incidents/types/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },
        {
          path: "incidents/types/edit/:id",
          name: "Edit Incident Type",
          component: () =>
            import("@/view/pages/manager/incidents/types/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_TRANSPORTER_ADMIN]
          }
        },

        // 17. Subscription types (just Superadmin)
        {
          path: "subscriptions/types",
          name: "Subscription Plans",
          component: () =>
            import("@/view/pages/manager/subscription/types/Index.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        },
        {
          path: "subscriptions/types/edit/:id",
          name: "Edit Subscription Plan",
          component: () =>
            import("@/view/pages/manager/subscription/types/Edit.vue"),
          meta: {
            requiresRoleID: [rolesList.ID_SUPERADMIN]
          }
        }
      ]
    },

    // Error pages
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/404.vue")
    },
    {
      path: "/402",
      name: "402",
      component: () => import("@/view/pages/error/402.vue")
    }
  ]
});
