import UserService from "@/core/services/api/v2/user.service";
import JwtService from "@/core/services/jwt.service";

// Named Action types
export const DO_AUTH = "doAuth";
export const CHANGE_NAME = "changeName";
export const CHANGE_AVATAR = "changeAvatar";
export const CHANGE_COMPANY = "changeCompany";
export const UNDO_AUTH = "undoAuth";
export const VERIFY_AUTH = "verifyAuth";

// Named Mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_NAME = "setName";
export const SET_AVATAR = "setAvatar";
export const SET_COMPANY = "setCompany";
export const SET_ERROR = "setError";

// Store
const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

// List of constant RoleID
const rolesList = Object.freeze({
  ID_SUPERADMIN: 1,
  ID_SENDER_ADMIN: 2,
  ID_SENDER_EMPLOYEE: 3,
  ID_TRANSPORTER_ADMIN: 4,
  ID_TRANSPORTER_DRIVER: 5,
  ID_OBSERVER: 6
});

// List of constant CompanyTypeID
const companyTypesList = Object.freeze({
  ID_NONE: 1,
  ID_SENDER: 2,
  ID_TRANSPORTER: 3
});

// List of constantSubscriptionTypeID
const subscriptionTypesList = Object.freeze({
  ID_SENDER_BASIC: 1,
  ID_SENDER_PREMIUM: 2,
  ID_TRANSPORTER_BASIC: 3,
  ID_TRANSPORTER_PREMIUM: 3
});

// Getters
const getters = {
  currentUser(state) {
    return state.user;
  },
  currentUserRoleID(state) {
    return state.user.Company ? state.user.Company.RoleID : 0;
  },
  currentUserName(state) {
    return state.user.Company ? state.user.Company.Name : "";
  },
  currentUserAvatar(state) {
    return state.user.Company ? state.user.Company.Avatar : "";
  },
  currentUserCompanyName(state) {
    return state.user.Company ? state.user.Company.CompanyName : "";
  },
  currentUserCompanyIsPremium(state) {
    return state.user.Company ? state.user.Company.IsPremium : false;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  rolesList() {
    return rolesList;
  },
  companyTypesList() {
    return companyTypesList;
  },
  subscriptionTypesList() {
    return subscriptionTypesList;
  }
};

// Actions
const actions = {
  [DO_AUTH](context, userData) {
    context.commit(SET_AUTH, userData);
  },
  [CHANGE_NAME](context, newName) {
    context.commit(SET_NAME, newName);
  },
  [CHANGE_AVATAR](context, newAvatar) {
    context.commit(SET_AVATAR, newAvatar);
  },
  [CHANGE_COMPANY](context, newCompany) {
    context.commit(SET_COMPANY, newCompany);
  },
  [UNDO_AUTH](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_AUTH](context) {
    UserService.checkUserActive()
      .then(statusCode => {
        if (parseInt(statusCode) !== 200) {
          context.commit(PURGE_AUTH);
        }
      })
      .catch(() => {
        context.commit(PURGE_AUTH);
      });
  }
};

// Mutations
const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    if (user.Token) {
      JwtService.saveToken(user.Token);
    }
  },
  [SET_NAME](state, newName) {
    state.user.Company.Name = newName;
  },
  [SET_AVATAR](state, newAvatar) {
    state.user.Company.Avatar = newAvatar;
  },
  [SET_COMPANY](state, newCompany) {
    state.user.Company = newCompany;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    window.sessionStorage.clear();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
