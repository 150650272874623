// Spanish

/* eslint-disable */

export const locale = {
  TRANSLATOR: {
    SELECT: "Elige tu idioma"
  },
  
  GENERIC: {
    404: "Página no encontrada",
    402: "Pago cancelado",
    ERROR: "Error",
    EDIT: "Editar", 
    EDIT_ITEMS: "Editar ítems",
    UNDO_CHANGES: "Deshacer cambios",
    SAVE: "Guardar",    
    BACK: "Volver",
    CLOSE: "Cerrar",
    CANCEL: "Cancelar",
    CONTINUE: "Continuar",
    OK: "OK",
    SAVE_OK: "Guardado correctamente",
    SAVE_ERROR: "Error al guardar",
    TRY_LATER: "Por favor inténtelo más tarde",
    CREATED_AT: "Fecha creación",
    ACTIONS: "Acciones",
    PREVIOUS: "Anterior",
    NEXT: "Siguiente",
    PAGINATION_MENU: "Registros por página",
    PAGINATION_SHOW: "Mostrando {from}-{to} de {total} registros",
    SEARCH: "Buscar",
    SEARCHING: "Buscando",
    NO_RESULTS: "Sin resultados",
    CLEAN_FILTERS: "Limpiar filtros",
    NOT_FOUND: "No encontrado",
    DELETE_ALERT: "¿Seguro que quieres eliminar?",
    DELETE_ALERT2: "Esta acción no puede deshacerse",
    DELETE: "Eliminar",
    DELETE_YES: "Sí, eliminar",
    DELETE_NO: "No, cancelar",
    DELETE_ERROR: "Error al eliminar",
    DELETE_OK: "Eliminado correctamente",
    OPTIONAL: "Opcional",
    YES: "Sí",
    NO: "No",
    PROCESSING_WAIT: "Procesando, por favor espere",
    N_D: "n/d",
    PRINT: "Imprimir",
    CREATION_DATE: "Fecha de creación",
    DEFAULT_VALUES: "Valores por defecto",
    SEND: "Enviar",
    DETAILS: "Detalles",
    DETAILS_OPEN: "Ver detalles",
    DETAILS_CLOSE: "Cerrar detalles",
    MULTIPLE_VALUES: "Múltiples valores",
    ALL: "Todos",
    ACCEPT_CONDITIONS_1: "He leído y acepto los",
    ACCEPT_CONDITIONS_2: "Términos y Condiciones de LogiOK",
    PRICE_FREE: "Gratis"
  },
  
  ROLE_NAMES_BY_ID: {
    1: "Superadmin",
    2: "Remitente Admin",
    3: "Remitente Empleado",
    4: "Transportista",
    5: "Chófer",
    6: "Observador"
  },
  
  COMPANY_TYPE_NAMES_BY_ID: {
    1: "Ninguna",
    2: "Remitente",
    3: "Transportista"
  },
  
  FARE_TYPE_NAMES_BY_ID: {
    1: "Estándar",
    2: "Ténder",
    3: "P2P",
    4: "Especial"
  },

  SHIPMENT_STATUS_NAMES_BY_ID: {
    1: "Pendiente de pago en LogiOK",
    2: "Pendiente de pago externo",
    3: "Esperando confirmación",
    4: "Contraoferta por transportista",
    5: "Contraoferta por remitente",
    6: "Oferta rechazada por transportista",
    7: "Oferta rechazada por remitente",
    8: "Oferta aceptada por transportista",
    9: "Oferta aceptada por remitente",
    10: "Pendiente de planificar",
    11: "Planificado",
    12: "Cancelado",
    13: "Finalizado",
    14: "Oferta cancelada por remitente"
  },
  
  SHIPMENT_LEG_TYPES_BY_ID: {
    1: "Ida",
    2: "Vuelta"
  },

  SUBSCRIPTION_HISTORY_ACTION_NAMES_BY_ID: {
    1: "Alta",
    2: "El usuario la pasó de Gratuita a Pago",
    3: "El usuario la pasó de Pago a Gratuita",
    4: "El superadmin la pasó de Gratuita a Pago",
    5: "El superadmin la pasó de Pago a Gratuita",
    6: "Renovación automática correcta",
    7: "Renovación automática fallida",
    8: "El superadmin la renovó manualmente",
    9: "El superadmin la pasó a Pendiente de Pago",
    10: "El usuario la canceló",
    11: "El superadmin la canceló"
  },

  VALIDATION: {
    INVALID: "{name} no es válido",
    REQUIRED: "{name} es requerido",
    MIN_LENGTH: "{name} debe tener una longitud mínima de {min}",
    MIN_VALUE: "Mínimo {min}",
    MAX_VALUE: "Máximo {max}",
    NOT_FOUND: "No se encontró {name}",
    REQUIRED_FIELD: "Campo requerido",
    MIN_LENGTH_FIELD: "Longitud mínima:",
    MAX_LENGTH_FIELD: "Longitud máxima:",
    INVALID_FIELD: "Campo no válido",
    INVALID_EMAIL: "Correo electrónico no válido",
    MISSING_REQUIRED_FIELDS: "Faltan campos requeridos",
    GREATER_THAN_MIN: "Debe ser mayor que el mínimo",
    INVALID_DATES: "Fechas incorrectas"
  },
  
  AUTH: {
    LOGIN: {    
      ASIDE_TITLE: "Bienvenido",
      ASIDE_DESCRIPTION: "LogiOK es la primera plataforma de gestión en entorno privado totalmente online que interconecta tu empresa, tus proveedores de transporte con tus clientes.",
      PRIVATE_AREA: "Área privada",
      DO_LOGIN: "Iniciar sesión",
      WRONG_CREDENTIALS: "Credenciales incorrectas",
      FORGOT_YOUR_PASSWORD: "¿Has olvidado tu contraseña?",
      SELECT_YOUR_COMPANY: "Elige tu empresa"
    },

    FORGOT: {
      RECOVER_TITLE: "Recuperar contraseña",
      RECOVER_DESC: "Introduce el email asociado a tu cuenta de LogiOK y te ayudaremos a recuperar tu contraseña.",
      RECOVER: "Recuperar",
      RECOVER_OK: "Mira en tu buzón de correo",
      BACK_TO_LOGIN: "Volver al acceso"
    },
    
    RESET: {
      RESET_TITLE: "Cambiar contraseña",
      RESET_DESC: "Introduce tu nueva contraseña"
    }
  },

  REGISTER: {
    TITLE: "Registro en LogiOK",
    QUESTION: {
      2: "¿Necesitas que traslademos tu vehículo?",
      3: "¿Te dedicas al transporte de vehículos?"
    },
    BUTTON: {
      2: "Regístrate como remitente",
      3: "Regístrate como transportista"
    },
    ALREADY_REGISTERED_1: "¿Ya tienes cuenta?",
    ALREADY_REGISTERED_2: "Iniciar sesión",
    CONTACT_DATA: "Datos de contacto",
    CONTACT_DATA_INFO: {
      2: "Se mostrarán a los Transportistas para comunicar contigo o enviarte las facturas",
      3: "Se mostrarán a los Remitentes para comunicar contigo. También será como LogiOK te pida la documentación necesaria para completar el registro."
    },
    ACCEPT_CONDITIONS_1: "He leído y acepto los",
    ACCEPT_CONDITIONS_2: "Términos y Condiciones de LogiOK",
    CONTINUE_FREE: "Registrarme y continuar",
    CONTINUE_PAY: "Pagar y continuar",
    WAITING_PAYMENT: "Esperando confirmación del pago",
    ERROR_409: "La empresa con ese CIF / NIF ya está dada de alta. Contacta con su responsable.",
    FINISHED_OK_1: "Registro tramitado.",
    FINISHED_OK_2: "Te hemos enviado los detalles por email.",
    FINISHED_KO: "Registro cancelado."
  },
  
  MENU: {
    HOME: "Inicio",
    LOGOUT: "Cerrar sesión",
    SENDERS: "Remitentes",
    TRANSPORTERS: "Transportistas",
    USERS: "Usuarios",
    SUBSCRIPTION_TYPES: "Planes de suscripción",
    VEHICLES: "Vehículos",
    MY_VEHICLES: "Mis vehículos",
    LOGISTICS: "Logística",
    INVOICE_CENTERS: "Centros de facturación",
    FREQUENT_ADDRESSES: "Direcciones frecuentes",
    OFFERS: "Ofertas",
    SHIPMENTS: "Envíos",
    SHIPMENTS_MANAGEMENT: "Gestión de envíos",
    INCIDENTS: "Incidencias",
    CONVERSATIONS: "Conversaciones",
    FARES: "Tarifas",
    SERVICES: "Servicios extra",
    FLEET: "Flota de transporte",
    DRIVERS: "Chóferes",
    OFFERS_RECEIVED: "Ofertas recibidas"
  },
  
  HOME: {
    WELCOME: "¡Bienvenido {name}!",
    OBSERVER_DESC: "Nuestra herramienta para clientes te permitirá consultar en qué estado se encuentra tu envío.",
    UPGRADE: "¿Quieres más funcionalidades?"
  },
  
  MY_PROFILE: {
    TITLE: "Mi perfil",
    SUBTITLE: "Datos de usuario",
    EMAIL: "Correo electrónico",
    ROLE: "Rol",
    NICK: "Alias",
    IDCARD: "DNI",
    FULLNAME: "Nombre y apellidos",
    PHONE: "Teléfono",
    AVATAR: "Imagen de perfil",
    CHANGE_AVATAR: "Cambiar imagen",
    PASSWORD: "Contraseña",
    PASSWORD_CURRENT: "Contraseña actual",
    PASSWORD_ONLY_TO_CHANGE: "Solo si quieres cambiarla",
    PASSWORD_NEW: "Contraseña nueva",
    PASSWORD_NEW_CONFIRM: "Repetir contraseña nueva",
    PASSWORD_MISMATCH: "Las dos copias de la contraseña no coinciden",
    ERROR_428: "La contraseña actual no es correcta",
    ERROR_412: "La nueva contraseña no es correcta"
  },
  
  MY_COMPANY: {    
    ACCOUNT_DATA: "Datos de la cuenta",
    CONFIG: "Configuración",
    MY_SENDERS: "Clientes habituales",
    MY_TRANSPORTERS: "Transportistas",
    MY_OBSERVERS: "Observadores",
    MY_USERS: "Datos de los usuarios del programa",
    EDIT_ADMIN_USER_TITLE: "Editar usuario principal",
    EDIT_ADMIN_USER_SUBTITLE: "Datos del usuario principal del programa",
    EDIT_CURRENT_SUBSCRIPTION_SUBTITLE: "Suscripción actual",
    CHANGE_SUBSCRIPTION: "Gestionar suscripción",
    CANCEL_SUBSCRIPTION: "Cancelar suscripción",
    CONFIRM_SUBSCRIPTION: "Confirmar suscripción",
    ALERT_CANCEL_1: "¿Seguro que quieres cancelar la suscripción?",
    ALERT_CANCEL_2: "Todos tus usuarios perderán el acceso a LogiOK",
    ALERT_CANCEL_YES: "Sí, cancelarla",
    ALERT_CANCEL_NO: "No, mantenerla",
    ALERT_CANCEL_KO: "Error al cancelar la suscripción",
    ALERT_CANCEL_OK: "Suscripción cancelada. ¡Adiós!",
    INFO_CHANGE: "Se cerrará la sesión de todos tus usuarios para que vuelvan a acceder con las nuevas funcionalidades.",
    INFO_PAY: "Se abrirá una nueva ventana donde realizar el pago.",
    ALERT_CHANGE_KO: "Error al modificar la suscripción",
    ALERT_CHANGE_OK: "Suscripción modificada. Vuelve a acceder.",
    MY_CONDITIONS: {
      TITLE: "Condiciones de servicio",
      INFO_1: "Indica los detalles que desees mostrar en tus resultados del Cotizador: condiciones de entrega, devoluciones, características de tu flota...",
      INFO_2: "Para eliminar una línea, deja su texto en blanco.",
      ADD_CONDITION: "Añadir condición",
      CONDITION: "Condición"
    }
  },
  
  USERS: {
    TITLE_INDEX: "Usuarios",
    TITLE_EDIT: "Usuario",
    SUBTITLE: "Datos de usuario",
    SEARCH: "Buscar usuario",
    STATUS: "Estado",
    ACTIVE_1: "Activo",
    ACTIVE_0: "Bloqueado",
    LINKED_COMPANIES: "Sin empresas | Conectado con 1 empresa | Conectado con {count} empresas",
    COMPANY: "Empresa",
    NEW_EMAIL_INFO: "Se enviará a esta dirección un mensaje de bienvenida con las credenciales de acceso"
  },

  COMPANIES: {
    SENDERS: {
      TITLE_INDEX: "Remitentes",
      TITLE_EDIT: "Remitente",
      SEARCH: "Buscar remitente"
    },
    TRANSPORTERS: {
      TITLE_INDEX: "Transportistas",
      TITLE_EDIT: "Transportista",
      SEARCH: "Buscar transportista"
    },
    SUBTITLE_FORM_ADMIN_USER: "Datos del usuario principal",
    SUBTITLE_FORM_BASIC: "Datos de facturación",
    SUBTITLE_FORM_DOCS: "Documentación adicional",
    SUBTITLE_FORM_SUBSCRIPTION: "Suscripción",
    SAVE_OK_FORM_USER: "Datos del usuario principal guardados correctamente",
    SAVE_ERROR_FORM_USER: "Error al guardar los Datos del usuario principal",
    SAVE_OK_FORM_BASIC: "Datos de facturación guardados correctamente",
    SAVE_ERROR_FORM_BASIC: "Error al guardar los Datos de facturación",
    SAVE_OK_FORM_SUBSCRIPTION: "Datos de suscripción guardados correctamente",
    SAVE_ERROR_FORM_SUBSCRIPTION: "Error al guardar los Datos de suscripción",
    COMPANY_CLASS: "Tipo de cliente",
    CODE: "Código",
    TRADE_NAME: "Nombre comercial",
    FISCAL_NAME: "Nombre fiscal",
    FISCAL_NAME_INFO: "Nombre fiscal empresa; nombre y apellidos para autónomos y particulares",
    FISCAL_ID: "CIF / NIF",
    FISCAL_ID_SHORT: "CIF",
    BRAND_NAME: "Marca",
    CONTACT_NAME: "Persona de contacto",
    CONTACT_EMAIL: "Correo electrónico del contacto",
    CONTACT_PHONE: "Teléfono del contacto",
    FULL_ADDRESS: "Dirección completa",
    POSTAL_CODE: "Código postal",
    CITY: "Localidad",
    PROVINCE: "Provincia",
    PAYMENT_DATA: {
      TITLE: "Datos para cobrar los envíos",
      TRANSPORTER: {
        INTERNAL_1: "Para los Remitentes Básicos, que pagan mediante tarjeta, necesitas una cuenta de Multisafepay.",
        INTERNAL_2: "Por tu seguridad, estos campos solo los puede modificar el administrador de LogiOK: te dará los detalles de los trámites a seguir.",
        EXTERNAL_1: "Para los Remitentes Premium, que pagan mediante transferencia, puedes indicar aquí los datos bancarios, y el programa los enviará automáticamente en el momento de la compra.",
        EXTERNAL_2: "O bien puedes no rellenar estos campos y comunicarlos por algún otro medio a cada cliente, junto con sus condiciones particulares de plazos de pago etc.",
        PAYMENT_PERCENT_SUPER: "% que cobra de cada compra",
        PAYMENT_PERCENT_ADMIN: "% que cobras de cada compra",
        MULTISAFEPAY_MERCHANT: "MerchantID de Multisafepay"
      },
      SENDER_PREMIUM: {
        SUBTITLE_SUPER: "Al ser Remitente Premium, indica aquí cuánto debería pagar el Transportista a LogiOK por cada envío, y si ya se ha validado toda la documentación necesaria.",
        SUBTITLE_ADMIN: "Al ser Remitente Premium, el Transportista deberá pagar a LogiOK una comisión por cada envío.",
        PAYMENT_PERCENT_SUPER: "% que paga de cada envío",
        PAYMENT_PERCENT_ADMIN: "% que pagas de cada envío",
        STATUS: "Estado de la documentación",
        STATUS_0: "Pendiente",
        STATUS_0_INFO: "se bloquea su acceso",
        STATUS_1: "Ya validada",
        STATUS_1_INFO: "se permite su acceso"
      },
      BANK_NAME: "Entidad",
      IBAN: "IBAN",
      SWIFT: "Código Swift"
    },
    SUBSCRIPTION: "Suscripción",
    SUBSCRIPTION_TYPE: "Tipo de suscripción",
    VALIDITY: "Vigencia",
    STATUS: "Estado",
    NOTES: "Notas",
    SUBSCRIPTION_STATUS: "Estado de la suscripción",
    CHANGE_SUBSCRIPTION_STATUS: "Cambiar el estado",
    CHANGE_TO_PAID: "Pagada - incrementará la fecha de renovación en los meses indicados",
    CHANGE_TO_UNPAID: "Pendiente de pago - cambiará la fecha de renovación a hoy",
    CHANGE_TO_CANCELLED: "Cancelada - se dejará de renovar",
    REGISTER_DATE: "Fecha de alta",
    RENEWAL_DATE: "Se renovará el",
    EXCEL_TITLE: "Exportar Excel"
  },
  
  DRIVERS: {
    TITLE_INDEX: "Chóferes",
    TITLE_EDIT: "Editar chófer",
    TITLE_ADD: "Añadir chófer",
    SUBTITLE: "Datos del chófer",
    SEARCH: "Buscar chófer",
    TRUCK: "Camión",
    LINKED_TRUCK: "Camión asociado",
    LINKED_TRUCK_INFO: "El chófer quedará asociado de manera automática a un camión cuando se establezca un nuevo servicio",
    UNLINKED_TRUCK: "Sin asignar",
    ERROR_409: "Ese alias ya está en uso",
    ERROR_418: "Ese correo electrónico ya está en uso por otro de tus chóferes"
  },
  
  EMPLOYEES: {
    TITLE_INDEX: "Empleados",
    TITLE_EDIT: "Editar empleado",
    TITLE_ADD: "Añadir empleado",
    SUBTITLE: "Datos del empleado",
    SEARCH: "Buscar empleado",
    ERROR_409: "Ese alias ya está en uso",
    ERROR_418: "Ese correo electrónico ya está en uso por otro de tus empleados"
  },
  
  OBSERVERS: {
    TITLE_INDEX: "Observadores",
    TITLE_EDIT: "Editar observador",
    TITLE_ADD: "Añadir observador",
    SUBTITLE: "Datos del observador",
    SEARCH: "Buscar observador",
    NICK_COMPANY: "Empresa",
    ERROR_409: "Ese alias ya está en uso",
    ERROR_418: "Ese correo electrónico ya está en uso por otro de tus observadores"
  },
  
  INVOICE_CENTERS: {
    TITLE_INDEX: "Centros de facturación",
    TITLE_EDIT: "Editar centro de facturación",
    TITLE_ADD_1: "Añadir centro",
    TITLE_ADD_2: "Añadir centro de facturación",
    SUBTITLE: "Datos del centro de facturación",
    SEARCH: "Buscar centro",
    NICK: "Alias",
    FISCAL_NAME: "Nombre fiscal",
    FISCAL_ID: "CIF",
    FULL_ADDRESS: "Dirección completa",
    POSTAL_CODE_1: "C. postal",
    POSTAL_CODE_2: "Código postal",
    CITY: "Localidad",
    PROVINCE: "Provincia",
    EMAIL_MANAGER: "Correo del responsable de administración",
    EMAIL_ACCOUNTING: "Correo al que enviar las facturas",
    ERROR_409: "Ese alias ya está en uso"
  },
  
  FREQUENT_ADDRESSES: {
    TITLE_INDEX: "Direcciones frecuentes",
    TITLE_INDEX_2: "Mis Direcciones",
    TITLE_EDIT: "Editar dirección",
    TITLE_ADD: "Añadir dirección",
    SUBTITLE: "Datos de la dirección",
    SEARCH: "Buscar dirección",
    NICK: "Alias",
    TYPE_1: "Tipo",
    TYPE_2: "Tipo de dirección",
    TRADE_NAME: "Nombre de la empresa / destinatario",
    TRADE_NAME_INFO: "El nombre con el que los chóferes identifican la empresa, por lo que debería ser el mismo que aparezca en el letrero",
    FULL_ADDRESS_1: "Dirección",
    FULL_ADDRESS_2: "Dirección completa",
    POSTAL_CODE_1: "C. postal",
    POSTAL_CODE_2: "Código postal",
    CITY: "Localidad",
    PROVINCE: "Provincia",
    CONTACT: "Contacto",
    CONTACT_NAME: "Persona de contacto",
    CONTACT_EMAIL: "Correo electrónico del contacto",
    CONTACT_PHONE: "Teléfono del contacto",
    ERROR_409: "Ese alias ya está en uso"
  },
  
  TENDER_ROUTES: {
    TITLE_INDEX: "Rutas tender",
    TITLE_INDEX_INFO: "Puedes crear rutas tender a partir de tus direcciones frecuentes. Una vez creada, los transportistas que trabajan esas provincias te propondrán su tarifa.",
    TITLE_EDIT: "Editar ruta tender",
    TITLE_ADD_1: "Añadir ruta",
    TITLE_ADD_2: "Añadir ruta tender",
    SUBTITLE: "Datos de la ruta",
    SEARCH: "Buscar ruta",
    ORIGIN: "Origen",
    ORIGIN_ADDRESS: "Dirección origen",
    DESTINATION: "Destino",
    DESTINATION_ADDRESS: "Dirección destino",
    STATUS: "Estado",
    ACTIVE: {
      "-1": "Pendiente de tarifar",
      0: "Pendiente",
      1: "Homologado"
    },
    FARES_WAITING_APPROVAL: "Sin transportistas nuevos | Tienes 1 transportista nuevo | Tienes {count} transportistas nuevos",
    PRICE_PER_TRUCK: "Precio carga completa",
    ROUTE_TRANSPORTERS: "Transportistas en esta ruta",
    ANY_ADDRESS_IN_PROVINCE: "Cualquier dirección en esta provincia",
    AT_LEAST_ONE_ADDRESS: "Debes seleccionar al menos una dirección concreta como origen o destino",
    REPEATED_ADDRESS: "La dirección destino debe ser distinta a la origen",
    ERROR_409: "Ya existe una ruta entre ese origen y ese destino"
  },
  
  MY_VEHICLES: {
    TITLE_INDEX: "Vehículos",
    TITLE_EDIT: "Ficha",
    TITLE_ADD: "Añadir Vehículo",
    SUBTITLE_DATA: "Datos del vehículo",
    SUBTITLE_HISTORY: "Historial",
    SEARCH: "Buscar vehículo",
    VEHICLE: "Vehículo",
    PLATE: "Nº de matrícula / bastidor",
    TYPE: "Tipo",
    VEHICLE_TYPE: "Tipo del vehículo",
    BRAND: "Marca",
    MODEL: "Modelo",
    VERSION: "Versión",
    YEAR: "Año",
    ACTIVE: "Activo",
    ACTIVE_INFO: "Marcar vehículo como \"Activo\" (se mostrará en tu listado cuando importes vehículos en el cotizador)",
    VEHICLE_STATUS: "Estado del vehículo",
    COLOR: "Color",
    NOTES: "Observaciones",
    ISTRAVELLING: "Viajando",
    ADDRESS: "Dirección",
    IN_OUT: "Acción",
    IN_OUT_true: "Entrada",
    IN_OUT_false: "Salida",
    DATE: "Fecha",
    ERROR_409: "Esa matrícula ya está en uso por otro de tus vehículos",
    EXCEL: {
      TITLE_1: "Importar Excel",
      TITLE_2: "Importar vehículos desde archivo Excel",
      INFO_GENERAL: "Puedes importar datos de varios vehículos a partir de nuestra plantilla",
      INFO_STATUS: "Estos son los posibles valores para la columna \"ID de Estado\"",
      INFO_MODEL: "Usa este buscador para los posibles valores para la columna \"ID de Modelo\"",
      STATUS_ID: "ID de Estado",
      STATUS_NAME: "Nombre",
      DOWNLOAD: "Descargar plantilla",
      DOWNLOAD_NAME: "LogiOK_Vehiculos",
      UPLOAD: "Subir archivo",
      UPLOADED_OK: "Archivo procesado, ver resultados debajo",
      RESULTS: "Resultados",
      CREATED_ROWS: "{count} vehículos creados",
      UPDATED_ROWS: "{count} vehículos actualizados",
      ERROR_ROWS: "{count} líneas erróneas",
      ROW: "Línea",
      CAUSE: "Motivo",
      ERROR_400: "Archivo Excel incorrecto",
      ERROR_412: "Falta la matrícula",
      ERROR_424: "ID de Modelo incorrecta",
      ERROR_428: "ID de Estado incorrecta"
    }
  },
  
  VEHICLE_MODELS: {
    TITLE_INDEX: "Vehículos",
    TITLE_EDIT: "Editar Vehículo",
    TITLE_ADD: "Añadir Vehículo",
    SUBTITLE_DATA: "Datos del vehículo",
    SUBTITLE_DIMENSIONS: "Dimensiones",
    SEARCH: "Buscar vehículo",    
    BRAND: "Marca",
    MODEL: "Modelo",
    MODEL_ID: "ID de Modelo",
    VERSION: "Versión",
    YEAR: "Año",
    YEAR_FROM: "Año desde",
    YEAR_TO: "Año hasta",
    TYPE: "Tipo",
    TYPES: "Tipos",
    VEHICLE_TYPE: "Tipo de vehículo",
    LENGTH: "Longitud (mm)",
    WIDTH: "Anchura (mm)",
    HEIGHT: "Altura (mm)",
    IMPORTED_FROM_CAR2DB: "Datos importados desde Car2db"
  },
  
  VEHICLE_TYPES: {
    TITLE_INDEX: "Tipos de vehículos",
    TITLE_EDIT: "Editar Tipo de Vehículo",
    TITLE_ADD: "Añadir Tipo de Vehículo",
    TITLE_ADD_1: "Añadir Tipo",
    SUBTITLE_DATA: "Tipo de Vehículo",
    SUBTITLE_DIMENSIONS: "Dimensiones",
    SEARCH: "Buscar tipo",    
    NAME: "Nombre",
    DESCRIPTION: "Descripción",
    SIZE: "Tamaño (mm)",
    LENGTH: "Longitud",
    WIDTH: "Anchura",
    HEIGHT: "Altura",
    MIN_MM: "Mín. (mm)",
    MAX_MM: "Máx. (mm)",
    INFO_IS_BASE: "Usado como base para el cálculo de tarifas"
  },
    
  FLEET: {
    TRUCKS: {
      TITLE_INDEX: "Camiones",
      TITLE_EDIT: "Editar camión",
      TITLE_ADD: "Añadir camión",
      SUBTITLE: "Datos del camión",
      SEARCH: "Buscar camiones",
      TRUCK: "Camión",
      NICK: "Alias",
      PLATE: "Matrícula / Nº Bastidor",
      TYPE: "Tipo",
      ERROR_409: "Ese alias ya está en uso por otro de tus camiones",
      ERROR_412: "Esa matrícula ya está en uso por otro de tus camiones"
    },
    CATEGORIES: {
      TITLE_INDEX: "Categorías de camiones",
      TITLE_EDIT: "Editar categoría",
      TITLE_ADD: "Añadir categoría",
      SUBTITLE_CAPACITY: "Capacidad",
      SEARCH: "Buscar categoría",
      CATEGORIES: "Categorías",
      CATEGORY: "Categoría",
      CATEGORY_INFO: "La categoría de tu camión se define según la capacidad del <strong>tipo de vehículo {typeBasePosition} o {typeBaseName}</strong>. Por ejemplo, un camión de categoría 8 podrá transportar hasta 8 unidades de {typeBaseName}.",
      CAPACITY: "Capacidad",
      TOTAL_CAPACITY: "Capacidad total (vehículos de Tipo {typeBasePosition})",
      CAPACITY_PER_TYPE: "Capacidad por tipo de vehículo",
      TYPE: "Tipo",
      COEFFICIENT: "Coeficiente corrector",
      COMPUTED_CAPACITY: "Capacidad del camión",
      DEFAULT: "Por defecto",
      DEFAULT_INFO: "Categoría de camión por defecto (se empleará esta categoría como referencia al fijar el precio de las tarifas)",
      INCREASE_OVER_DEFAULT: "% de descuento o recargo en las tarifas respecto a la categoría por defecto",
      VOLUME_DISCOUNT: "Ajuste de las tarifas según nivel de ocupación",
      VOLUME_DISCOUNT_INFO: "Puedes fijar un descuento según las unidades a transportar de Tipo {typeBasePosition}. A partir de un total igual o superior a la carga completa, se ofertará el importe de Carga Completa.",
      VOLUME_DISCOUNT_HEADER_1: "Nº vehículos de Tipo {typeBasePosition}",
      VOLUME_DISCOUNT_HEADER_2: "Capacidad ocupada",
      VOLUME_DISCOUNT_HEADER_3: "Descuento por unidades",
      ERROR_409: "Esa capacidad ya está en uso por otra de tus categorías",
      ERROR_422: "Debes indicar la capacidad para cada uno de los tipos de vehículo",
      ERROR_424: "Debes indicar el descuento para cada uno de los niveles de ocupación"
    }
  },
  
  SERVICES: {
    TITLE_INDEX: "Servicios extra",
    TITLE_INDEX_INFO_1: "Servicios adicionales que el remitente puede elegir en el momento de la compra, ya sea con un coste o gratis.",
    TITLE_INDEX_INFO_2: "Ej.: lavar los vehículos, entregar en el dropoff del puerto...",
    TITLE_EDIT: "Editar servicio",
    TITLE_ADD_1: "Añadir servicio",
    TITLE_ADD_2: "Añadir servicio extra",
    SUBTITLE: "Datos del servicio",
    SEARCH: "Buscar servicio",
    NAME: "Nombre",
    PROVINCE: "Provincia",
    PROVINCES: "Provincias",
    PRICE_PER_TYPE: "Precio por tipo de vehículo (€, impuestos incluidos)",
    TYPE: "Tipo"
  },
  
  FARES: {
    COMMON: {
      TITLE_ADD_1: "Añadir tarifa",
      EXCEL_TITLE_1: "Importar Excel",
      SEARCH: "Buscar tarifa",
      FARE: "Tarifa",
      DISCOUNTS: "Descuentos",
      SUBTITLE_DATA: "Datos de la tarifa",
      ORIGIN: "Origen",
      DESTINATION: "Destino",
      ESTIMATED_DAYS: "Tiempo estimado (días)",
      PRICE_PER_TRUCK: "Precio carga completa (€, impuestos incluidos)",
      PRICE_PER_TRUCK_SHORT: "Precio carga completa",
      PRICE_PER_TRUCK_INFO: "Introduce la tarifa que desees cobrar por carga completa, para la <strong><a href=\"/manager/trucks/categories\">categoría de camión</a></strong> que hayas marcado por defecto.",
      PRICE_PER_UNIT: "Precio unidad suelta (€, impuestos incluidos)",
      PRICE_PER_UNIT_SHORT: "Precio unidad suelta",
      PRICE_PER_UNIT_INFO: "Introduce la tarifa que desees cobrar cuando no se alcance la carga completa, por cada unidad de <strong>Tipo {typeBasePosition} o inferior</strong>.",
      PRICE_PER_VEHICLE: "Recargo por vehículo (€, impuestos incluidos)",
      PRICE_PER_VEHICLE_INFO: "Se sumará al precio que corresponda por el nivel de ocupación del camión. Por ejemplo, si deseas añadir el flete del barco para envíos a islas o Ceuta o Melilla - con diferentes importes según el tamaño.",
      SURCHARGE: "Recargo",
      ALLOW_ONLY_PREMIUM: "Mostrar esta tarifa únicamente a los clientes premium en el cotizador",
      PERCENT_INCREASE_NOT_PREMIUM: "Recargo para clientes casual (%)",
      PERCENT_INCREASE_NOT_PREMIUM_INFO: "Puedes aplicar un porcentaje de recargo sobre la tarifa estándar para aquellos clientes que no disponen de un plan de pago."
    },
    STANDARD: {
      TITLE_INDEX: "Estándar",
      TITLE_INDEX_INFO_1: "Indique sus Tarifas Estándar desde una provincia a otra, estableciendo un precio por carga completa y opcionalmente un recargo por vehículo.",
      TITLE_INDEX_INFO_2: "Las puede añadir/editar/eliminar manualmente una por una, o bien descargarse un Excel con todas las combinaciones, modificarlo y luego subirlo.",
      TITLE_EDIT: "Editar tarifa estándar",
      TITLE_ADD_2: "Añadir tarifa estándar",      
      ERROR_409: "Ya existe otra tarifa entre esa pareja de provincias",
      EXCEL: {
        TITLE_2: "Importar tarifas estándar desde archivo Excel",
        INFO_GENERAL: "Puedes importar datos de las tarifas para todas las provincias a partir de nuestra plantilla.",
        DOWNLOAD: "Descargar plantilla",
        DOWNLOAD_NAME: "LogiOK_Tarifas_Estandar",
        UPLOAD: "Subir archivo",
        UPLOADED_OK: "Archivo procesado, ver resultados debajo",
        RESULTS: "Resultados",
        CREATED_ROWS: "{count} tarifas creadas",
        UPDATED_ROWS: "{count} tarifas actualizadas",
        ERROR_ROWS: "{count} líneas erróneas",
        ROW: "Línea",
        CAUSE: "Motivo",
        ERROR_400: "Archivo Excel incorrecto",
        ERROR_412: {
          1: "Falta la provincia origen",
          2: "Falta la provincia destino"
        },
        ERROR_424: "Precio de carga completa incorrecto",
        ERROR_428: "Días estimados incorrectos"
      }
    },
    TENDER: {
      TITLE_INDEX: "Tender",
      TITLE_INDEX_INFO_1: "Aquí puedes ver todos los clientes que te han propuesto alguna Ruta Tender.",
      TITLE_INDEX_INFO_2: "Al igual que en las tarifas estándar, puedes establecer un precio por carga completa y opcionalmente un recargo por vehículo.",
      TITLE_EDIT: "Editar tarifa tender",
      STATUS: "Estado",
      FARES_WAITING_PRICE: "No hay tarifas pendientes | Tienes 1 tarifa pendiente | Tienes {count} tarifas pendientes",
      TITLE_INDEX_FROM_SENDER: "Tarifas Tender"
    },
    P2P: {
      TITLE_INDEX: "P2P",
      TITLE_INDEX_INFO_1: "Las tarifas P2P están orientadas para los servicios que se producen en zonas geográficas cercanas, y cuya tarificación no depende de transportes de alta capacidad, sino más puntuales.",
      TITLE_INDEX_INFO_2: "Puedes establecer varios códigos postales de origen y destino, y escoger entre un precio por Km recorrido o tarifa plana.",
      TITLE_EDIT: "Editar tarifa P2P",
      TITLE_ADD_2: "Añadir tarifa P2P" 
    }
  },
  
  OFFERS: {
    COMMON: {
      SEARCH: "Buscar ofertas",
      EXCEL_TITLE_1: "Exportar Excel",
      ID: "Id.",
      CREATION_DATE: "Fecha solicitud",
      VEHICLES: "Vehículos",
      ORIGIN: "Origen",
      DESTINATION: "Destino",
      PATH: "Trayecto",
      JUST_THERE: "Solo ida",
      THERE_AND_BACK: "Ida y vuelta",
      THERE: "Ida",
      BACK: "Vuelta",
      TRANSPORTER: "Transportista",
      SENDER: "Remitente",
      TRANSPORTER_NOTES: "Observaciones del transportista",
      SENDER_NOTES: "Observaciones del remitente",
      FARE: "Tarifa",
      PRICE_TOTAL: "Precio total",
      TAXES_INCLUDED: "Impuestos incluidos",
      ORIGIN_DATE: "Fecha de recogida",
      ORIGIN_DATE_DESIRED: "Fecha de dispon.",
      ORIGIN_DATE_DESIRED_BACK_THERE: "Cuando reciba los vehículos",
      ORIGIN_DATE_PLANNED: "Fecha prevista de recogida",
      DESTINATION_DATE: "Fecha de entrega",
      DESTINATION_DATE_RANGE: "Plazo de entrega deseado",
      DESTINATION_DATE_TIME: "Horario de entrega",
      DESTINATION_DATE_DESIRED: "Fecha est. entrega",
      DESTINATION_DATE_PLANNED: "Fecha prevista de entrega",
      AVAILABILITY: "Disponibilidad",
      PLANNED: "Prevista",
      FLEXIBLE: "Flexible",
      STATUS: "Estado",
      STATUS_AFTER_OFFER: "Aceptada: en Envíos",
      REVIEW: "Revisar",
      OTHER_DATA: "Otros datos",
      OBSERVERS: "Observador/es",
      INVOICE_CENTER: "Centro de facturación",
      SEND_COUNTEROFFER: "Enviar contraoferta",
      ACCEPT_OFFER: "Aceptar oferta",
      ACCEPT_ALERT: "¿Seguro que quieres aceptarla?",
      ACCEPT_OK: "Oferta aceptada",
      REJECT_OFFER: "Rechazar oferta",
      REJECT_ALERT: "¿Seguro que quieres rechazarla?",
      REJECT_OK: "Oferta rechazada",
      CANCEL_OFFER: "Cancelar oferta",
      CANCEL_ALERT: "¿Seguro que quieres cancelarla?",
      CANCEL_OK: "Oferta cancelada",
      MESSAGES: "Mensajes"
    },
    STANDARD: {
      TITLE_INDEX: "Transportes",
      DOWNLOAD_NAME: "LogiOK_Ofertas",
      TITLE_EDIT: "Oferta",
      STATUS_INFO: {
        1: {
         SENDER: "Se está tramitando el pago a través de LogiOK.",
         TRANSPORTER: "Se está tramitando el pago a través de LogiOK."
        },
        2: {
         SENDER: "El transportista te hará llegar los detalles para realizar el pago: número de cuenta, plazo...",
         TRANSPORTER: "Envía al remitente los detalles para realizar el pago: número de cuenta, plazo... Cuando recibas el dinero, acepta o rechaza. También puedes realizar los cambios que consideres oportunos y enviar una oferta revisada al remitente."
        },
        3: {
         SENDER: "El transportista ha recibido tu oferta y está a la espera de ser aceptada o rechazada.",
         TRANSPORTER: "Revisa los datos de la oferta antes de aceptar o rechazar el servicio. También puedes realizar los cambios que consideres oportunos y enviar una oferta revisada al remitente."
        },
        4: {
         SENDER: "El transportista ha estudiado tu oferta y ha decidido enviarte una contraoferta, con unas nuevas condiciones. Revísala y decide si quieres aceptarla, rechazarla o modificarla.",
         TRANSPORTER: "Se ha enviado tu contraoferta al remitente y está a la espera de ser aceptada o rechazada."
        },
        5: {
         SENDER: "Se ha enviado tu contraoferta al transportista y está a la espera de ser aceptada o rechazada.",
         TRANSPORTER: "El remitente ha estudiado tu oferta y ha decidido enviarte una contraoferta, con unas nuevas condiciones. Revísala y decide si quieres aceptarla, rechazarla o modificarla."
        },
        14: {
         SENDER: "Has cancelado esta petición de oferta.",
         TRANSPORTER: "El remitente ha decidido cancelar esta petición de oferta."
        }
      },
      ABSTRACT: "Resumen de la oferta",
      VEHICLES_DATA: "Datos de los vehículos",
      ORIGIN_DATA: "Datos de origen",
      DESTINATION_DATA: "Datos de destino",
      ORIGIN_ADDRESS: "Dirección de origen",
      DESTINATION_ADDRESS: "Dirección de destino",
      ORIGIN_CONTACT: "Contacto de origen",
      DESTINATION_CONTACT: "Contacto de destino"
    },
    SPECIAL: {
      TITLE_INDEX: "Transp. Especial"
    },
    TENDER: {
      TITLE_INDEX: "Solicitudes Tender"
    }
  },

  SHIPMENTS: {
    VIEW_SHIPMENT: "Ver envío",
    TITLE_INDEX: "Envíos",
    TITLE_EDIT: "Editar envío",
    SEARCH: "Buscar envío",
    ID: "Id. Envío",
    CREATION_DATE: "Fecha creación",
    VEHICLE: "Vehículo",
    ORIGIN: "Origen",
    DESTINATION: "Destino",
    PATH: "Trayecto",
    TRANSPORTER: "Transportista",
    DRIVER: "Chófer",
    SENDER: "Remitente",
    PRICE_AT_FINISH: "Precio final",
    COMMISSION_PERCENT: "% comisión",
    FINISHED_AT: "Fecha de finalización",
    TOTAL: "Total",
    TAXES_INCLUDED: "Impuestos incluidos",
    ORIGIN_DATE_DESIRED: "Fecha dispon.",
    ORIGIN_DATE_DONE: "Fecha de recogida realizada",
    DESTINATION_DATE: "Fecha est. entrega",
    DESTINATION_DATE_DONE: "Fecha de entrega realizada",
    STATUS: "Estado",
    WITH_INCIDENTS_1: "con inc.",
    WITH_INCIDENTS_2: "Con incidencias",
    EXCEL_TITLE: "Exportar Excel",
    DOWNLOAD_NAME: "LogiOK_Envios",
    CHECKED_ITEMS: "Sin items | 1 item seleccionado | {count} items seleccionados",
    PAYMENT_DATA: {
        TITLE: "Detalles del pago",
        EXTERNAL: "Por transferencia bancaria",
        CREDIT_CARD: "Tarjeta de crédito",
        CARD_NUMBER: "Número de tarjeta",
        AMOUNT: "Cantidad",
        DATE: "Fecha de pago"
    },
    PLANNING: {
      TITLE: "Planificación",
      STATUS_INFO: {
        10: {
          SENDER: "El transportista ha recibido tu envío y será planificado en breve.",
          TRANSPORTER: "Revisa los datos del envío antes de realizar la entrega. También puedes gestionar el servicio asignando el chófer que desees, así como modificar las fechas de recogida y entrega."
        },
        11: {
          SENDER: "El transportista ha planificado tu envío y será enviado en breve.",
          TRANSPORTER: "Revisa los datos del envío antes de realizar la entrega. También puedes gestionar el servicio asignando el chófer que desees, así como modificar las fechas de recogida y entrega."
        },
        12: {
          SENDER: "El envío de este vehículo ha sido cancelado por una incidencia.",
          TRANSPORTER: "El envío de este vehículo ha sido cancelado por una incidencia."
        },
        13: {
          SENDER: "El vehículo ha sido entregado en destino.",
          TRANSPORTER:  "El vehículo ha sido entregado en destino."
        }
      },
      VEHICLE_COUNT: "1 Vehículo | {n} Vehículos",
      INVOICE_TO_TITLE: "Facturar a",
      INVOICE_TO_ID: "CIF/NIF",
      VEHICLE_DATA: "Datos del vehículo",
      DONE: "Realizada"
    },
    DOCS: {
      TITLE: "Archivos",
      TITLE_2: "Archivos adjuntos",
      UPLOAD: "Subir archivo"
    },
    INCIDENTS: {
      TITLE: "Incidencias",
      TITLE_2: "Incidencias del envío",
      FINISHED: "Tramitada",
      UNFINISHED: "Sin tramitar",
      TITLE_ADD: "Reportar incidencia",
      TYPE: "Tipo",
      PRICE_INCREASE: "Incremento de precio",
      CANCEL_DATE: "Fecha de cancelación",
      NOTES: "Comentario",
      NOTES_INFO: "Introduzca un comentario sobre la incidencia",
      MARK_FINISHED: "Marcar incidencia como tramitada"
    }
  },
  
  CHATS: {
    FROM_MYSELF: "Yo",
    AND_VEHICLES: " | y 1 vehículo más | y {count} vehículos más",
    TITLE: "Conversaciones",
    SEARCH: "Buscar conversaciones",
    SHIPMENT_ID: "ID. Envío",
    LATEST_MSG: "Últ. mensaje",
    FROM: "Enviado por",
    DATE: "Fecha"
  },
  
  INCIDENTS: {
      TITLE: "Incidencias no tramitadas",
      SEARCH: "Buscar incidencias",
      CREATED_AT: "Fecha inc.",
      TYPE: "Tipo inc.",
      TYPES: {
          TITLE: "Tipos de incidencia",
          SEARCH: "Buscar tipo de incidencia",
          SUBTITLE_EDIT: "Tipo de incidencia",
          NAME_1: "Nombre",
          NAME_2: "Nombre incidencia",
          CANCELS_1: "¿Cancela el envío?",
          CANCELS_2: "¿La incidencia cancela el envío?",
          TITLE_ADD_1: "Añadir tipo",
          TITLE_ADD_2: "Añadir tipo de incidencia",
          TITLE_EDIT: "Editar tipo de incidencia"
      }
  },
  
  SUBSCRIPTION_TYPES: {
      TITLE_INDEX: "Planes de suscripción",
      TITLE_EDIT: "Plan de suscripción",
      PER_MONTHS: "0 meses | 1 mes | {count} meses",
      IS_DEFAULT: "Por defecto",
      SUBTITLE_DATA: "Datos de la suscripción",
      SUBTITLE_DESCRIPTION: "Funcionalidades de la suscripción",
      NAME: "Nombre",
      PRICE_PER_MONTH: "Precio (€/mes)",
      PRICE_SENDER_DEFAULT_FREE: "La suscripción del remitente por defecto debe ser gratis",
      MONTHS: "Renovable cada (meses)",
      ADD_DESCRIPTION: "Añadir funcionalidad",
      DESCRIPTION: "Funcionalidad"
  },

  HEADER: {
    TITLE: "LogiOK",
    SEARCH: "Buscar transportes",
    SENDER: "¿Tienes una empresa?",
    TRANSPORTER: "¿Eres transportista?",
    CONTACT: "Contacto"
  },

  FOOTER: {
    CONTACT: "Contacto",
    COMPANY: "Empresa",
    HOW_IT_WORKS: "¿Cómo funciona?",
    TRANSPORTER: "¿Eres transportista?",
    CONTACT_US: "Contacta con nosotros",
    FOLLOW_US: "Síguenos",
    SECURE_PAYMENT: "Pago seguro",
    TERMS: "Términos y condiciones del servicio",
    PRIVACY_POLICY: "Política de privacidad",
    LEGAL_NOTE: "Aviso legal",
    COOKIES: "Política de cookies",
    COOKIES_BANNER_1: "Esta página web utiliza únicamente las cookies necesarias para el correcto funcionamiento de tu cuenta.",
    COOKIES_BANNER_2: "Pulsa aquí para más información sobre cómo utilizamos las cookies.",
    COOKIES_BANNER_BUTTON: "Acepto esas cookies"
  },
  
  PRICE_SEARCH: {
    QUESTION: "¿Necesitas contratar un transporte de vehículos?",
    GO_TO_SEARCH_1: "Cotizador",
    GO_TO_SEARCH_2: "Ir al cotizador",
    STEP1: {
      TITLE: "Cotizador de servicios de transporte",
      ROUTE: "Ruta",
      ORIGIN: "Origen",
      ORIGIN_ADDRESS: "Dirección origen",
      DESTINATION: "Destino",
      DESTINATION_ADDRESS: "Dirección destino",
      IMPORT_FREQUENT_ADDRESS: "Importar dirección frecuente",
      IMPORT_TENDER_ROUTE: "Importar ruta tender",
      OUR_SERVICES: "Nuestros servicios",
      OWN_A_COMPANY: "¿Tienes una empresa?",
      JUST_THERE: "Solo ida",
      THERE_AND_BACK: "Ida y vuelta",
      THERE: "Ida",
      BACK: "Vuelta",
      SAME_VEHICLES: "Voy a enviar los mismos vehículos que en la ida",
      SAME_VEHICLES_2: "Los mismos que en la ida",
      VEHICLE: "Vehículo",
      VEHICLES: "Vehículos",
      VEHICLE_INFO: "Introduzca los datos de su vehículo",
      VEHICLE_ADD: "Añadir vehículo",
      VEHICLE_STATUS: "Selecciona el estado del vehículo",
      VEHICLE_STATUS_INFO: "En caso de tener más de un vehículo con distintos estados, necesitarás introducirlos en varias entradas",
      QUANTITY: "Cantidad",
      IMPORT_MY_VEHICLE: "Importar desde \"Mi Stock\"",
      DESTINATION_DATE_DESIRED: "Plazo de entrega deseado",
      ONE_WEEK: "Dentro de una semana",
      TWO_WEEKS: "Dentro de dos semanas",
      SPECIFIC_DATE: "En una fecha específica",
      SPECIFIC_RANGE: "Entre fechas específicas",
      FLEXIBLE: "Flexible",
      FLEXIBLE_CHEAPER: "Si escoges flexible, obtendrás los mejores precios del mercado. De lo contrario el transportista puede que aplique algún sobrecoste.",
      ERROR_409: "La dirección destino debe ser distinta al origen",
      ERROR_412: "Fechas incorrectas"
    },
    STEP2: {
      DELIVERY: "Entrega",
      DELIVERY_ONE_WEEK: "Entrega en una semana",
      DELIVERY_TWO_WEEKS: "Entrega en dos semanas",
      ESTIMATED_DELIVERY: "Entrega estimada",
      VEHICLE_COUNT: "1 Vehículo | {n} Vehículos",
      NEW_SEARCH: "Modificar búsqueda",
      OFFER_COUNT_0: "No hemos encontrado Ofertas", 
      OFFER_COUNT_1: "Hemos encontrado un total de",
      OFFER_COUNT_2: "0 Ofertas | 1 Oferta | {n} Ofertas",
      OFFER_COUNT_3: "para la ruta y vehículos seleccionados.",
      OFFER_COUNT_4: "Puedes ajustar tu búsqueda modificando los filtros.",
      OFFER_THERE_AND_BACK: "El precio y días estimados corresponden a la suma de la ida y de la vuelta.",
      ORDER_BY: "Ordenar por",
      LOW_PRICE: "Más baratas primero",
      HIGH_PRICE: "Más caras primero",
      LOW_DAYS: "Menos días primero",
      HIGH_DAYS: "Más días primero",
      HIGH_RATING: "Más valoradas primero",
      FARE: "Tarifa",
      TAXES_INCLUDED: "Impuestos incluidos",
      DAYS: "Días",      
      DETAILS_SERVICE: "Condiciones del transportista",
      DETAILS_LOGIOK: "Condiciones de LogiOK",
      CONDITIONS_LOGIOK: [
        "El lugar de carga y descarga debe ser accesible legalmente para camiones porta vehículos, en caso contrario la empresa que haya contratado le ofrecerá alternativas.",
        "Las recogidas y entregas se realizan en las rutas que unen las grandes ciudades a nivel Nacional. En el caso que la población este fuera de las rutas estándar, será informado con antelación si conlleva algún sobre coste.",
        "El vehículo debe estar en disposición de ser cargado a partir de la fecha de disponibilidad de recogida que haya seleccionado.",
        "El plazo de recogida y entrega contratado es orientativo, actualizándose una vez realizado el pago del transporte, y estando sujeto dicho plazo a posibles modificaciones que le serán informadas por la empresa que haya contratado.",
        "En los casos de tramos marítimos o transporte internacional los plazos de recogida y entrega se detallarán según su caso.",
        "El vehículo no tendrá en su interior objetos o enseres personales en su interior, ni piezas sueltas del propio vehículo.",
        "En caso de anulación del traslado, una vez se ha iniciado el mismo, consulte con la empresa contratante las condiciones de anulación.",
        "Los vehículos deberán entregarse totalmente vacíos para su transporte; en caso de portar objetos en su interior, se transportará por cuenta y riesgo del propietario del vehículo.",
        "El vehículo en carga queda asegurado según condiciones CMR y L.O.T.T. ley 15/2009. Para vehículos de valor muy elevado consulte con la empresa contratante por si se debiera contratar un seguro adicional."
      ],
      TRANSPORTER_DATA: "Datos del transportista",
      RATING_COUNT: "1 Valoración | {n} Valoraciones",
      PURCHASE_NOW: "¡Contratar ahora!"
    },
    STEP3: {
      OFFER_ABSTRACT: "Resumen de la oferta",
      TOTAL: "Total",      
      EXTRA_DATA: {
        TITLE: "Contratación",
        ORIGIN_DATA: "Datos de origen",
        DESTINATION_DATA: "Datos de destino",
        CONTACT: "Contacto",
        SAVE_ADDRESS: "Guardar dirección en mi listado de direcciones frecuentes",
        SAVE_ADDRESS_INFO: "Marca esta opción si deseas importar la dirección en tus próximas contrataciones",
        VEHICLES_DATA: "Datos de los vehículos",
        VEHICLES_DATA_INFO: "Necesitamos identificar los vehículos que vas a enviar por su matrícula o bastidor.",
        SAVE_VEHICLES: "Guardar vehículos en \"Mi Stock\"",
        SAVE_VEHICLES_INFO: "Marca esta opción si deseas importar los datos de estos vehículos en tus próximas contrataciones",
        PLATE_REPEATED: "Matrícula repetida",
        DATES_DATA: "Fechas del envío",
        ORIGIN_DATE_DESIRED: "Fecha de disponibilidad de recogida",
        ORIGIN_DATE_DESIRED_INFO: "Fecha a partir de la cual el vehículo debe estar en disposición de ser cargado en el lugar de origen",
        ORIGIN_DATE_DESIRED_BACK: "Fecha de disponibilidad para la vuelta",
        ORIGIN_DATE_DESIRED_BACK_THERE: "Cuando reciba los vehículos",
        DESTINATION_DATE_ESTIMATED: "Fecha estimada de entrega",
        DESTINATION_DATE_ESTIMATED_INFO: "Fechas y horarios de entrega orientativos, el transportista tratará de adaptarse al plazo escogido en función de su disponibilidad",
        DESTINATION_DATE_DESIRED_TIME: "Horario de entrega",
        ALL_DAY: "Durante todo el día",
        MORNING: "Mañana",
        AFTERNOON: "Tarde",
        NOTES: "Observaciones",
        NOTES_INFO: "Introduzca las observaciones sobre su envío",
        SERVICES: "Servicios adicionales",
        SERVICES_INFO: "El transportista ofrece los siguientes servicios adicionales para tu envío",
        SERVICES_ORIGIN: "Servicios en origen",
        SERVICES_ORIGIN_NONE: "No se han encontrado servicios adicionales para este origen",
        SERVICES_DESTINATION: "Servicios en destino",
        SERVICES_DESTINATION_NONE: "No se han encontrado servicios adicionales para este destino"
      },
      REGISTER_DATA: {
        TITLE: "Registro",
        TITLE_2: "¿Aún no eres miembro de LogiOK?",
        COPY: "Completar con los datos de origen del envío. Modifica los datos importados que no sean correctos."
      },
      FINISH: {
        TITLE: "Finalizar y pagar",  
        OTHER_DATA: "Otros datos",
        OBSERVERS: "Observador/es",
        OBSERVERS_INFO: "Da de alta a alguien que quieres que esté informado de este transporte",
        INVOICE_CENTER: "Centro de facturación",
        INVOICE_CENTER_INFO: "Seleccionar centro",
        PAYMENT_METHOD: "Elige un método de pago",
        PAYMENT_INTERNAL: "Tarjeta de crédito y débito",
        PAYMENT_INTERNAL_INFO_1: "Se abrirá una nueva ventana de la pasarela de pago.",
        PAYMENT_INTERNAL_INFO_2: "Pago online procesado de forma 100% segura por Multisafepay.",
        PAYMENT_INTERNAL_INFO_3: "Los datos de tu tarjeta no serán guardados en nuestros servidores.",
        PAYMENT_EXTERNAL: "Transferencia bancaria",
        PAYMENT_EXTERNAL_INFO: "El transportista te indicará las condiciones: número de cuenta, plazo...",
        CONFIRM_PAYMENT: "Confirmar pago",
        ERROR_402: "No se puede conectar con la pasarela de pago",
        ERROR_406: "No se encuentran los datos de la búsqueda",
        ERROR_410: "No se encuentran los datos de la ruta",
        ERROR_418: "No se encuentran los datos de la oferta",
        ERROR_409: "La dirección destino debe ser distinta a la origen",
        ERROR_411: "Faltan datos de los vehículos de la ida",
        ERROR_415: "Faltan datos de los vehículos de la vuelta"
      }
    },
    PURCHASE_OK: {
      THANK_YOU: "¡Gracias por tu contratación!",
      THANK_YOU_INFO_1: "Te hemos enviado un email con la confirmación.",
      THANK_YOU_INFO_2: "Tu envío está siendo procesado y será gestionado por el transportista lo antes posible.",
      TRANSPORTER: "Transportista",
      OFFER_ABSTRACT: "Resumen de la oferta"
    },
    PURCHASE_KO: {
      TITLE: "Compra cancelada"
    }
  },
  
  LANDING: {
    INDEX: {
      BLOCK1: {
        TITLE: "Encuentra un transporte para tu vehículo, al instante",
        PARAGRAPH: "LogiOK es un comparador online gratuito, donde sin intermediarios y en una sola búsqueda, te ayudaremos a conseguir las mejores ofertas de todas las empresas de transporte del mercado.",
        BUTTON: "Buscar"
      },
      BLOCK2: {
        TITLE: "Nuestros servicios",
        PARAGRAPH: "Con LogiOK podrás realizar cualquier tipo de envío. Si necesitas un transporte especial, contacta con nosotros y te ayudaremos a encontrar una oferta a medida para ti.",
        SHIP: "Barco",
        CONFIDENTIAL: "Camión confidencial",
        GROUP: "Camión grupaje",
        OPEN: "Grúa express abierta",
        DRIVER: "Driver",
        MOTORCYCLES: "Motos",
        HEAVY: "Pesados",
        HIGH: "Furgones  + 3,20m altura",
        BUTTON: "Quiero un transporte especial"
      },
      BLOCK3: {
        TITLE: "¿Cómo funciona?",
        PARAGRAPH1: "Es muy fácil: Introduce tu solicitud de transporte y te ayudaremos a encontrar las mejores ofertas.",
        PARAGRAPH2: "LogiOK te permite conectar con nuestros transportistas, previamente verificados."
      },
      BLOCK4: {
        STEP1: {
          TAG: "1. Búsqueda",
          TITLE: "Completa tu solicitud de transporte", 
          PARAGRAPH1: "Comienza introduciendo qué vehículo deseas transportar, dónde y en qué plazo lo necesitas. Te mostraremos las ofertas de los transportistas disponibles para la ruta y fechas elegidas.",
          PARAGRAPH2: "Rellenar nuestro cotizador te llevará menos de un minuto.",
          PARAGRAPH3: "Si tienes una empresa, puedes agilizar este proceso accediendo a tus direcciones y vehículos frecuentes.",
          PARAGRAPH4: "Puedes contratar envíos de ida y vuelta, así como de múltiples destinos."
        },
        STEP2: {
          TAG: "2. Contratación",
          TITLE: "Contrata un servicio de transporte", 
          PARAGRAPH1: "Revisa y compara entre todas las ofertas proporcionadas por nuestros transportistas.",
          PARAGRAPH2: "Ofertas completas con toda la información que necesites: plazo de entrega, precio total, condiciones, valoraciones del transportista...",
          PARAGRAPH3: "Todos nuestros transportistas están verificados."
        },
        STEP3: {
          TAG: "3. Seguimiento",
          TITLE: "Sigue el estado de tu envío en todo momento", 
          PARAGRAPH1: "¡No te pierdas ningún paso durante el envío de tu vehículo!",
          PARAGRAPH2: "Comunicación directa con el transportista mediante nuestro chat.",
          PARAGRAPH3: "Seguimiento de los estados del envío para conocer todas las novedades durante el transporte.",
          PARAGRAPH4: "Pago seguro y reembolsable."
        }
      },
      BLOCK5: {
        TITLE: "¿Por qué elegir LogiOK?",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        STEP1: {
          TITLE: "Flexibilidad absoluta", 
          PARAGRAPH: "Contamos con un equipo experto en el transporte de vehículos que nos permite ofrecerte un servicio completo. Nuestra logística se adapta a las necesidades de cada cliente. Desde el vehículo (cualquier tipo y estado), a la ruta y plazos que necesites."
        },
        STEP2: {
          TITLE: "Seguro y al mejor precio", 
          PARAGRAPH: "Todos nuestros transportistas están verificados. Además, puedes consultar las valoraciones de otros clientes. Además, al estar haciendo viajes similares, sus ofertas de presupuestos son más baratas que los precios habituales."
        },
        STEP3: {
          TITLE: "Cumplimos los plazos", 
          PARAGRAPH: "Podrás conocer en cada momento dónde se encuentra tu vehículo durante su transporte. Te informamos de las fechas de entrega y recogida, además de las incidencias que puedan surgir, aportando soluciones."
        },
        STEP4: {
          TITLE: "Comunicación y soporte", 
          PARAGRAPH: "Siempre en contacto con el cliente para responder tus consultas, mostrarte el estado de tu transporte y ofrecerte las soluciones que necesites. Podrás consultar online el estado de tu envío con nuestra plataforma para clientes."
        }
      }
    },
    SENDER: {
      BLOCK1: {
        PRETITLE: "LogiOK Empresas",
        TITLE: "La mejor solución de transporte para tu empresa",
        PARAGRAPH: "En LogiOK conseguirás los mejores precios del mercado, sin intermediarios, ni comisiones. Consultas ruta, seleccionas modelo y unidades, y contratas directamente a la empresa de transporte que más se adapte a tus necesidades.",
        BUTTON: "Regístrate en LogiOK"
      },
      BLOCK2: {
        TITLE: "Busca, compara y contrata",
        STEP1: {
          TITLE: "Flexibilidad total",
          PARAGRAPH: "Nos adaptamos a tus necesidades en cada transporte: envío múltiple de vehículos de cualquier tipo y estado, en las fechas que nos lo indiques, a varios destinos..."
        },
        STEP2: {
          TITLE: "Al instante, sin intermediarios",
          PARAGRAPH: "Conecta directamente con multitud de empresas de transporte. Solo tienes que buscar la ruta que necesites realizar para descubrir todas las ofertas disponibles."
        },
        STEP3: {
          TITLE: "Optimizada para tu empresa",
          PARAGRAPH: "LogiOk te ofrece una solución adaptada para tu negocio: gestiona tu flota de vehículos, direcciones y clientes habituales, centros de facturación..."
        }
      },
      BLOCK3: {
        TITLE: "Funciones para empresas",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        FEATURE1: "Gestión de los usuarios de tu empresa",
        FEATURE2: "Trabaja con tus direcciones y vehículos frecuentes",
        FEATURE3: "Gestiona tus centros de facturación",
        FEATURE4: "Envíos multivehículo y multiruta",
        FEATURE5: "Busca y compara entre multitud de ofertas",
        FEATURE6: "Escoge los plazos de entrega deseados",
        FEATURE7: "Descuentos temporales y para clientes habituales",
        FEATURE8: "Valoraciones de otros clientes",
        FEATURE9: "Seguimiento del transporte",
        FEATURE10: "Sistema de incidencias",
        FEATURE11: "Comunicación directa con transportistas vía chat"
      },
      BLOCK4: {
        TITLE: "Planes de subscripción",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        BUTTON: "¡Me interesa!"
      }
    },
    TRANSPORTER: {
      BLOCK1: {
        PRETITLE: "LogiOK Transportistas",
        TITLE: "Recibe nuevos trabajos de transporte cada día",
        PARAGRAPH: "Te ofrecemos soluciones digitales inteligentes, para que la relación con tus clientes sea eficaz, segura y transparente.",
        BUTTON: "Regístrate como Transportista"
      },
      BLOCK2: {
        TITLE: "¿Cómo funciona?",
        BUTTON: "Comenzar",
        STEP1: {
          TAG: "1. Configura tu empresa",
          TITLE: "Configura tu empresa", 
          PARAGRAPH1: "Comienza definiendo los recursos de tu empresa, como la flota de camiones y remolques, tus conductores o los servicios extra que ofreces en el transporte (p.ej. limpieza del vehículo).",
          PARAGRAPH2: "Nuestro sistema de categorías de remolques te permitirá definir de manera fácil y precisa su capacidad.",
          PARAGRAPH3: "Podrás asociar un conductor un camión para que se le asigne automáticamente cuando recibas un servicio."
        },
        STEP2: {
          TAG: "2. Publica tus tarifas",
          TITLE: "Publica tus tarifas", 
          PARAGRAPH1: "Establece cuáles son las tarifas de las rutas que realices para recibir nuevos trabajos de transporte.",
          PARAGRAPH2: "Sólo tienes que introducir el origen, destino y precio por carga completa. Nuestro sistema calculará de manera automática el precio a ofertar según el nivel de ocupación del camión en cada transporte.",
          PARAGRAPH3: "3 tipos de tarifas: Estándar, con direcciones de clientes (Tender) o para servicios de cercanía (P2P).",
          PARAGRAPH4: "Podrás agilizar este proceso importando varias tarifas de manera conjunta desde un archivo Excel."
        },
        STEP3: {
          TAG: "3. Realiza el transporte",
          TITLE: "Realiza el transporte", 
          PARAGRAPH1: "Cuando un cliente contrate un transporte, recibirás un aviso con la oferta detallada.",
          PARAGRAPH2: "Podrás decidir si aceptas el servicio o no, o enviar una contraoferta, modificando el precio o las fechas.",
          PARAGRAPH3: "Seguimiento del estado del envío (App móvil para conductores) y gestor de incidencias para visualizar y gestionar aquellas que se reporten durante el transporte.",
          PARAGRAPH4: "Comunicación directa con el cliente mediante chat.."
        }
      },
      BLOCK3: {
        TITLE: "Funciones para transportistas",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        FEATURE1: "Gestión de los usuarios de tu empresa",
        FEATURE2: "Cálculo automático de las ofertas según ocupación",
        FEATURE3: "Tarifas específicas para clientes habituales",
        FEATURE4: "Tarifas de transporte en cercanías",
        FEATURE5: "Publicación de descuentos temporales",
        FEATURE6: "Gestión de las fechas de entrega y recogida",
        FEATURE7: "Comunicación directa con clientes vía chat",
        FEATURE8: "Asignación automática de conductor por servicio",
        FEATURE9: "Seguimiento del transporte",
        FEATURE10: "App móvil para conductores",
        FEATURE11: "Gestión de incidencias",
        FEATURE12: "Valoraciones de clientes"
      },
      BLOCK4: {
        TITLE: "Planes de subscripción",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        BUTTON: "¡Me interesa!"
      }
    },
    PRICE_SEARCH: {
      BLOCK1: {
        STEP1: {
          TITLE: "¿Cuánto tardará la entrega?",
          PARAGRAPH: "Podrás escoger entre distintos plazos de entrega para tu vehículo, o, si lo deseas, marcar una fecha específica."
        },
        STEP2: {
          TITLE: "¿Puedo enviar cualquier vehículo?",
          PARAGRAPH: "Sí, nuestra plataforma te permitirá enviar vehículos de cualquier marca y modelo, sea cual sea su estado."
        },
        STEP3: {
          TITLE: "¿Mi vehículo está asegurado?",
          PARAGRAPH: "Sí, tu vehículo está asegurado contra todos los riesgos que puedan surgir durante el transporte."
        }
      },
      BLOCK2: {
        TITLE: "Nuestros clientes"
      }
    },
    CONTACT: {
      TITLE: "Contacto",
      CALL: "Llámanos",
      WRITE: "Envíanos un correo",
      WHERE: "Estamos en...",
      WHEN: "Nuestro horario",
      DAYS: "Lunes a Viernes",
      FORM: {
        TITLE: "¿Tienes alguna duda?",
        PARAGRAPH: "Completa el formulario y un representante de LogiOK te responderá a la mayor brevedad posible.",
        FULLNAME: "Nombre y apellidos*",
        PHONE: "Teléfono*",
        EMAIL: "Email*",
        REFERENCE: "Referencia de envío",
        MESSAGE: "Mensaje*",
        SEND: "Enviar",
        SEND_OK_1: "Mensaje enviado correctamente",
        SEND_OK_2: "Te responderemos lo antes posible",
        SEND_KO: "Error al enviar"
      }
    }
  }
};
