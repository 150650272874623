// English

/* eslint-disable */

export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  
  GENERIC: {
    404: "Page not found",
    402: "Payment cancelled",
    ERROR: "Error",
    EDIT: "Editar", 
    EDIT_ITEMS: "Edit items", 
    UNDO_CHANGES: "Undo changes",
    SAVE: "Guardar",
    BACK: "Back",
    CLOSE: "Close",
    CANCEL: "Cancel",
    CONTINUE: "Continue",
    OK: "OK",
    SAVE_OK: "Saved OK",
    SAVE_ERROR: "Error when saving",
    TRY_LATER: "Please try again later",
    CREATED_AT: "Creation date",
    ACTIONS: "Actions",
    PREVIOUS: "Previous",
    NEXT: "Next",
    PAGINATION_MENU: "Items per page",
    PAGINATION_SHOW: "Showing {from}-{to} of {total} items",
    SEARCH: "Search",
    SEARCHING: "Searching",
    NO_RESULTS: "No results",
    CLEAN_FILTERS: "Clean filters",
    NOT_FOUND: "Not found",
    DELETE_ALERT: "Are you sure to delete?",
    DELETE_ALERT2: "This action can't be undone",
    DELETE: "Delete",
    DELETE_YES: "Yes, delete",
    DELETE_NO: "No, cancel",
    DELETE_ERROR: "Error when deleting",
    DELETE_OK: "Deleted OK",
    OPTIONAL: "Optional",
    YES: "Sí",
    NO: "No",
    PROCESSING_WAIT: "Processing, please wait",
    N_D: "n/d",
    PRINT: "Print",
    CREATION_DATE: "Creation Date",
    DEFAULT_VALUES: "Default values",
    SEND: "Send",
    DETAILS: "Details",
    DETAILS_OPEN: "View details",
    DETAILS_CLOSE: "Close details",
    MULTIPLE_VALUES: "Multiple values",
    ALL: "All",
    ACCEPT_CONDITIONS_1: "He leído y acepto los",
    ACCEPT_CONDITIONS_2: "Términos y Condiciones de LogiOK",
    PRICE_FREE: "Gratis"
  },
  
  ROLE_NAMES_BY_ID: {
    1: "Superadmin",
    2: "Sender Admin",
    3: "Sender Employee",
    4: "Transporter",
    5: "Driver",
    6: "Observer"
  },
  
  COMPANY_TYPE_NAMES_BY_ID: {
    1: "None",
    2: "Sender",
    3: "Transporter"
  },
  
  FARE_TYPE_NAMES_BY_ID: {
    1: "Standard",
    2: "Tender",
    3: "P2P",
    4: "Special"
  },

  SHIPMENT_STATUS_NAMES_BY_ID: {
    1: "Pendiente de pago en LogiOK",
    2: "Pendiente de pago externo",
    3: "Esperando confirmación",
    4: "Contraoferta por transportista",
    5: "Contraoferta por remitente",
    6: "Oferta rechazada por transportista",
    7: "Oferta rechazada por remitente",
    8: "Oferta aceptada por transportista",
    9: "Oferta aceptada por remitente",
    10: "Pendiente de planificar",
    11: "Planificado",
    12: "Cancelado",
    13: "Finalizado",
    14: "Oferta cancelada por remitente"
  },
  
  SHIPMENT_LEG_TYPES_BY_ID: {
    1: "There",
    2: "Back"
  },

  SUBSCRIPTION_HISTORY_ACTION_NAMES_BY_ID: {
    1: "Alta",
    2: "El usuario la pasó de Gratuita a Pago",
    3: "El usuario la pasó de Pago a Gratuita",
    4: "El superadmin la pasó de Gratuita a Pago",
    5: "El superadmin la pasó de Pago a Gratuita",
    6: "Renovación automática correcta",
    7: "Renovación automática fallida",
    8: "El superadmin la renovó manualmente",
    9: "El superadmin la pasó a Pendiente de Pago",
    10: "El usuario la canceló",
    11: "El superadmin la canceló"
  },

  VALIDATION: {
    INVALID: "{name} is not valid",
    REQUIRED: "{name} is required",
    MIN_LENGTH: "{name} minimum length is {min}",
    MIN_VALUE: "Minimum {min}",
    MAX_VALUE: "Maximum {max}",
    NOT_FOUND: "The requested {name} is not found",
    REQUIRED_FIELD: "Required field",
    MIN_LENGTH_FIELD: "Minimum field length:",
    MAX_LENGTH_FIELD: "Maximum field length:",
    INVALID_FIELD: "Field is not valid",
    INVALID_EMAIL: "Invalid email",
    MISSING_REQUIRED_FIELDS: "Missing required fields",
    GREATER_THAN_MIN: "Must be grater than min",
    INVALID_DATES: "Invalid dates"
  },
    
  AUTH: {
    LOGIN: {
      ASIDE_TITLE: "Welcome",
      ASIDE_DESCRIPTION: "LogiOK is the first platform de gestión en entorno privado totalmente online que interconecta tu empresa, tus proveedores de transporte con tus clientes.",
      PRIVATE_AREA: "Private Area",
      DO_LOGIN: "Sign in",
      WRONG_CREDENTIALS: "Wrong credentials",
      FORGOT_YOUR_PASSWORD: "Forgot your password?",
      SELECT_YOUR_COMPANY: "Select your Company"
    },

    FORGOT: {
      RECOVER_TITLE: "Recover password",
      RECOVER_DESC: "Enter the email of your LogiOK account and we'll help you to recover your password.",
      RECOVER: "Recover",
      RECOVER_OK: "Look into your mailbox",
      BACK_TO_LOGIN: "Back to login"
    },
    
    RESET: {
      RESET_TITLE: "Reset password",
      RESET_DESC: "Enter your new password"
    }
  },

  REGISTER: {
    TITLE: "Registro en LogiOK",
    QUESTION: {
      2: "¿Necesitas que traslademos tu vehículo?",
      3: "¿Te dedicas al transporte de vehículos?"
    },
    BUTTON: {
      2: "Regístrate como remitente",
      3: "Regístrate como transportista"
    },
    ALREADY_REGISTERED_1: "¿Ya tienes cuenta?",
    ALREADY_REGISTERED_2: "Iniciar sesión",
    CONTACT_DATA: "Datos de contacto",
    CONTACT_DATA_INFO: {
      2: "Se mostrarán a los Transportistas para comunicar contigo o enviarte las facturas",
      3: "Se mostrarán a los Remitentes para comunicar contigo. También será como LogiOK te pida la documentación necesaria para completar el registro."
    },
    ACCEPT_CONDITIONS_1: "He leído y acepto los",
    ACCEPT_CONDITIONS_2: "Términos y Condiciones de LogiOK",
    CONTINUE_FREE: "Registrarme y continuar",
    CONTINUE_PAY: "Pagar y continuar",
    WAITING_PAYMENT: "Esperando confirmación del pago",
    ERROR_409: "La empresa con ese CIF / NIF ya está dada de alta. Contacta con su responsable.",
    FINISHED_OK_1: "Registro tramitado.",
    FINISHED_OK_2: "Te hemos enviado los detalles por email.",
    FINISHED_KO: "Registro cancelado."
  },
  
  MENU: {
    HOME: "Home",
    LOGOUT: "Logout",
    SENDERS: "Senders",
    TRANSPORTERS: "Transporters",
    USERS: "Users",
    SUBSCRIPTION_TYPES: "Subscription Plans",
    VEHICLES: "Vehicles",
    MY_VEHICLES: "My vehicles",
    LOGISTICS: "Logisctics",
    INVOICE_CENTERS: "Invoice Centers",
    FREQUENT_ADDRESSES: "Frequent Adresses",
    OFFERS: "Offers",
    SHIPMENTS: "Shipments",
    SHIPMENTS_MANAGEMENT: "Shipments Management",
    INCIDENTS: "Incidents",
    CONVERSATIONS: "Conversations",
    FARES: "Fares",
    SERVICES: "Extra Services",
    FLEET: "Transport Fleet",
    DRIVERS: "Drivers",
    OFFERS_RECEIVED: "Received Offers"
  },
  
  HOME: {
    WELCOME: "Welcome {name}!",
    OBSERVER_DESC: "Nuestra herramienta para clientes te permitirá consultar en qué estado se encuentra tu envío.",
    UPGRADE: "¿Quieres más funcionalidades?"
  },
  
  MY_PROFILE: {
    TITLE: "My profile",
    SUBTITLE: "User data",
    EMAIL: "Email",
    ROLE: "Role",
    NICK: "Nick",
    IDCARD: "ID Card",
    FULLNAME: "Name and surname",
    PHONE: "Phone",
    AVATAR: "Profile image",
    CHANGE_AVATAR: "Change image",
    PASSWORD: "Password",
    PASSWORD_CURRENT: "Current password",
    PASSWORD_ONLY_TO_CHANGE: "Only if you want to change it",
    PASSWORD_NEW: "New password",
    PASSWORD_NEW_CONFIRM: "Repeat new password",
    PASSWORD_MISMATCH: "Password confirmation mismatch",
    ERROR_428: "Wrong current password",
    ERROR_412: "Wrong new password"
  },

  MY_COMPANY: {    
    ACCOUNT_DATA: "Account Data",
    CONFIG: "Configuration",
    MY_SENDERS: "Usual Customers",
    MY_TRANSPORTERS: "Transporters",
    MY_OBSERVERS: "Observers",
    MY_USERS: "Program Users data",
    EDIT_ADMIN_USER_TITLE: "Edit main user",
    EDIT_ADMIN_USER_SUBTITLE: "Data of the main user of the program",
    EDIT_CURRENT_SUBSCRIPTION_SUBTITLE: "Current subscription",
    CHANGE_SUBSCRIPTION: "Manage subscription",
    CANCEL_SUBSCRIPTION: "Cancel subscription",
    CONFIRM_SUBSCRIPTION: "Confirm subscription",
    ALERT_CANCEL_1: "¿Are you sure you want to cancel your subscription?",
    ALERT_CANCEL_2: "All yur users will lose access to LogiOK",
    ALERT_CANCEL_YES: "Yes, cancel it",
    ALERT_CANCEL_NO: "No, keep it",
    ALERT_CANCEL_KO: "Error when cancelling the subscription",
    ALERT_CANCEL_OK: "Subscription cancelled. Bye!",
    INFO_CHANGE: "All your users will be logged out so you log-in again with the new features",
    INFO_PAY: "A new window will open to make the payment.",
    ALERT_CHANGE_KO: "Error when changing the subscription",
    ALERT_CHANGE_OK: "Subscription changed. Log in again.",
    MY_CONDITIONS: {
      TITLE: "Condiciones de servicio",
      INFO_1: "Indica los detalles que desees mostrar en tus resultados del Cotizador: condiciones de entrega, devoluciones, características de tu flota...",
      INFO_2: "Para eliminar una línea, deja su texto en blanco.",
      ADD_CONDITION: "Añadir condición",
      CONDITION: "Condición"
    }
  },
  
  USERS: {
    TITLE_INDEX: "Users",
    TITLE_EDIT: "User",
    SUBTITLE: "User data",
    SEARCH: "Search user",
    STATUS: "Status",
    ACTIVE_1: "Active",
    ACTIVE_0: "Blocked",
    LINKED_COMPANIES: "No Companies | Linked with 1 company | Linked with {count} Companies",
    COMPANY: "Company",
    NEW_EMAIL_INFO: "Se enviará a esta dirección un mensaje de bienvenida con las credenciales de acceso"
  },

  COMPANIES: {
    SENDERS: {
      TITLE_INDEX: "Senders",
      TITLE_EDIT: "Sender",
      SEARCH: "Search Sender"
    },
    TRANSPORTERS: {
      TITLE_INDEX: "Transporters",
      TITLE_EDIT: "Transporter",
      SEARCH: "Search Transporter"
    },
    SUBTITLE_FORM_ADMIN_USER: "Main User data",
    SUBTITLE_FORM_BASIC: "Invoice data",
    SUBTITLE_FORM_DOCS: "Extra documentation",
    SUBTITLE_FORM_SUBSCRIPTION: "Subscription",   
    SAVE_OK_FORM_USER: "Main User data saved OK",
    SAVE_ERROR_FORM_USER: "Error when saving Main User data",
    SAVE_OK_FORM_BASIC: "Invoice data saved OK",
    SAVE_ERROR_FORM_BASIC: "Error when saving Invoice data",
    SAVE_OK_FORM_SUBSCRIPTION: "Subscription data saved OK",
    SAVE_ERROR_FORM_SUBSCRIPTION: "Error when saving Subscription data",
    COMPANY_CLASS: "Client Type",
    CODE: "Code",
    TRADE_NAME: "Trade Name",
    FISCAL_NAME: "Nombre fiscal",
    FISCAL_NAME_INFO: "Nombre fiscal empresa; nombre y apellidos para autónomos y particulares",
    FISCAL_ID: "CIF / NIF",
    FISCAL_ID_SHORT: "CIF",
    BRAND_NAME: "Brand",
    CONTACT_NAME: "Contact person",
    CONTACT_EMAIL: "Contact email",
    CONTACT_PHONE: "Contact phone",
    FULL_ADDRESS: "Full address",
    POSTAL_CODE: "Postal Code",
    CITY: "City",
    PROVINCE: "Provincia",
    PAYMENT_DATA: {
      TITLE: "Datos para cobrar los envíos",
      TRANSPORTER: {
        INTERNAL_1: "Para los Remitentes Básicos, que pagan mediante tarjeta, necesitas una cuenta de Multisafepay.",
        INTERNAL_2: "Por tu seguridad, estos campos solo los puede modificar el administrador de LogiOK: te dará los detalles de los trámites a seguir.",
        EXTERNAL_1: "Para los Remitentes Premium, que pagan mediante transferencia, puedes indicar aquí los datos bancarios, y el programa los enviará automáticamente en el momento de la compra.",
        EXTERNAL_2: "O bien puedes no rellenar estos campos y comunicarlos por algún otro medio a cada cliente, junto con sus condiciones particulares de plazos de pago etc.",
        PAYMENT_PERCENT_SUPER: "% que cobra de cada compra",
        PAYMENT_PERCENT_ADMIN: "% que cobras de cada compra",
        MULTISAFEPAY_MERCHANT: "Multisafepay MerchantID"
      },
      SENDER: {
        SUBTITLE_SUPER: "Al ser Remitente Premium, indica aquí cuánto debería pagar el Transportista a LogiOK por cada envío, y si ya se ha validado toda la documentación necesaria.",
        SUBTITLE_ADMIN: "Al ser Remitente Premium, el Transportista deberá pagar a LogiOK una comisión por cada envío.",
        PAYMENT_PERCENT_SUPER: "% que paga de cada envío",
        PAYMENT_PERCENT_ADMIN: "% que pagas de cada envío",
        STATUS: "Estado de la documentación",
        STATUS_0: "Pendiente",
        STATUS_1: "Ya validada"
      },
      BANK_NAME: "Bank name",
      IBAN: "IBAN",
      SWIFT: "Swift Code"
    },
    SUBSCRIPTION: "Subscription",
    SUBSCRIPTION_TYPE: "Subscription type",
    VALIDITY: "Validity",
    STATUS: "Status",
    NOTES: "Notas",
    SUBSCRIPTION_STATUS: "Subscription status",
    CHANGE_SUBSCRIPTION_STATUS: "Change status",
    CHANGE_TO_PAID: "Paid - renewal date will increment in those months",
    CHANGE_TO_UNPAID: "Payment pending - renewal date will be set to today",
    CHANGE_TO_CANCELLED: "Cancelled - won't renew anymore",
    REGISTER_DATE: "Register date",
    RENEWAL_DATE: "Will renew on",
    EXCEL_TITLE: "Export Excel"
  },
  
  DRIVERS: {
    TITLE_INDEX: "Drivers",
    TITLE_EDIT: "Edit Driver",
    TITLE_ADD: "Add Driver",
    SUBTITLE: "Driver data",
    SEARCH: "Search Driver",
    TRUCK: "Truck",
    LINKED_TRUCK: "Linked Truck",
    LINKED_TRUCK_INFO: "Driver will be automatically linked to a Truck when a new service is established",
    UNLINKED_TRUCK: "Unassigned",
    ERROR_409: "That Nick is already used",
    ERROR_418: "That Email is already used by another of your Drivers"
  },

  EMPLOYEES: {
    TITLE_INDEX: "Employees",
    TITLE_EDIT: "Editar Employee",
    TITLE_ADD: "Añadir Employee",
    SUBTITLE: "Employee data",
    SEARCH: "Search Employee",    
    ERROR_409: "That Nick is already used",
    ERROR_418: "That Email is already used by another of your Employees"
  },

  OBSERVERS: {
    TITLE_INDEX: "Observers",
    TITLE_EDIT: "Edit Observer",
    TITLE_ADD: "Add Observer",
    SUBTITLE: "Observer data",
    SEARCH: "Search Observer",
    NICK_COMPANY: "Company",
    ERROR_409: "That Nick is already used",
    ERROR_418: "That Email is already used by another of your Observers"
  },
  
  INVOICE_CENTERS: {
    TITLE_INDEX: "Invoice Centers",
    TITLE_EDIT: "Edit Invoice Center",
    TITLE_ADD_1: "Add Center",
    TITLE_ADD_2: "Add Invoice Center",
    SUBTITLE: "Invoice Center data",
    SEARCH: "Search center",
    NICK: "Alias",
    FISCAL_NAME: "Fiscal Name",
    FISCAL_ID: "CIF",
    FULL_ADDRESS: "Full address",
    POSTAL_CODE_1: "Postal C.",
    POSTAL_CODE_2: "Postal Code",
    CITY: "City",
    PROVINCE: "Province",
    EMAIL_MANAGER: "Administration Manager email",
    EMAIL_ACCOUNTING: "Email where send the invoices to",
    ERROR_409: "That Nick is already used"
  },
  
  FREQUENT_ADDRESSES: {
    TITLE_INDEX: "Frequent Addresses",
    TITLE_INDEX_2: "My Addresses",
    TITLE_EDIT: "Edit Address",
    TITLE_ADD: "Add Address",
    SUBTITLE: "Address data",
    SEARCH: "Search Address",
    NICK: "Nick",
    TYPE_1: "Type",
    TYPE_2: "Address Type",
    TRADE_NAME: "Company / Person Name",
    TRADE_NAME_INFO: "El nombre con el que los chóferes identifican la empresa, por lo que debería ser el mismo que aparezca en el letrero",
    FULL_ADDRESS_1: "Address",
    FULL_ADDRESS_2: "Full address",
    POSTAL_CODE_1: "Postal C.",
    POSTAL_CODE_2: "Postal Code",
    CITY: "City",
    PROVINCE: "Province",
    CONTACT: "Contact",
    CONTACT_NAME: "Contact person",
    CONTACT_EMAIL: "Contact email",
    CONTACT_PHONE: "Contact phone",
    ERROR_409: "Ese alias ya está en uso"
  },
  
  TENDER_ROUTES: {
    TITLE_INDEX: "Tender Routes",
    TITLE_INDEX_INFO: "Puedes crear rutas tender a partir de tus direcciones frecuentes. Una vez creada, los transportistas que trabajan esas provincias te propondran su tarifa.",
    TITLE_EDIT: "Edit Tender Routes",
    TITLE_ADD_1: "Add Route",
    TITLE_ADD_2: "Add Tender Routes",
    SUBTITLE: "Route data",
    SEARCH: "Search route",
    ORIGIN: "Origin",
    ORIGIN_ADDRESS: "Origin Address",
    DESTINATION: "Destination",
    DESTINATION_ADDRESS: "Destination Address",
    STATUS: "Status",
    ACTIVE: {
      "-1": "Pendiente de tarifar",
      0: "Pendiente",
      1: "Homologado"
    },
    FARES_WAITING_APPROVAL: "No new transporters | 1 new transporter | {count} new transporters",
    PRICE_PER_TRUCK: "Full load price",
    ROUTE_TRANSPORTERS: "Transporters on this Route",
    ANY_ADDRESS_IN_PROVINCE: "Any address in this Province",
    AT_LEAST_ONE_ADDRESS: "You must choose at least one specific address, origin or destination",
    REPEATED_ADDRESS: "Destination address must be different than origin address",
    ERROR_409: "There is already a Route from that Origin to that Destination"
  },
  
  MY_VEHICLES: {
    TITLE_INDEX: "Vehicles",
    TITLE_EDIT: "Profile",
    TITLE_ADD: "Add Vehicle",
    SUBTITLE_DATA: "Vehicle data",
    SUBTITLE_HISTORY: "History",
    SEARCH: "Search Vehicle",
    VEHICLE: "Vehicle",
    PLATE: "Plate / Frame Number",
    TYPE: "Type",
    VEHICLE_TYPE: "Vehicle Type",
    BRAND: "Brand",
    MODEL: "Model",
    VERSION: "Version",
    YEAR: "Year",
    ACTIVE: "Active",
    ACTIVE_INFO: "Set vehicle as \"Active\" (will appear on your listing when you import vehicles into the price search)",
    VEHICLE_STATUS: "Vehicle status",
    COLOR: "Color",
    NOTES: "Notes",
    ISTRAVELLING: "Travelling",
    ADDRESS: "Dirección",
    IN_OUT: "Acción",
    IN_OUT_true: "Entrada",
    IN_OUT_false: "Salida",
    DATE: "Fecha",
    ERROR_409: "Esa matrícula ya está en uso por otro de tus vehículos",
    EXCEL: {
      TITLE_1: "Import Excel",
      TITLE_2: "Import Vehicles from Excel file",
      INFO_GENERAL: "You can import several Vehicles data with our template",
      INFO_STATUS: "These are the possible values for \"Status ID\" column",
      INFO_MODEL: "Use this form to search possible values for \"Model ID\" column",
      STATUS_ID: "Status ID",
      STATUS_NAME: "Name",
      DOWNLOAD: "Download template",
      DOWNLOAD_NAME: "LogiOK_Vehicles",
      UPLOAD: "Upload file",
      UPLOADED_OK: "File processed, see results below",
      RESULTS: "Results",
      CREATED_ROWS: "{count} created Vehicles",
      UPDATED_ROWS: "{count} updated Vehicles",
      ERROR_ROWS: "{count} wrong rows",
      ROW: "Row",
      CAUSE: "Cause",
      ERROR_400: "Wrong Excel file",
      ERROR_412: "Missing Plate",
      ERROR_424: "Wrong Model ID",
      ERROR_428: "Wrong Status ID"
    }
  },

  VEHICLE_MODELS: {
    TITLE_INDEX: "Vehicles",
    TITLE_EDIT: "Edit Vehicle",
    TITLE_ADD: "Add Vehicle",
    SUBTITLE_DATA: "Vehicle data",
    SUBTITLE_DIMENSIONS: "Dimensions",
    SEARCH: "Search Vehicle",    
    BRAND: "Brand",
    MODEL_ID: "Model ID",
    MODEL: "Model",
    VERSION: "Version",
    YEAR: "Year",
    YEAR_FROM: "Year from",
    YEAR_TO: "Year to",
    TYPE: "Type",
    TYPES: "Types",
    VEHICLE_TYPE: "Vehicle Type",
    LENGTH: "Length (mm)",
    WIDTH: "Witdh (mm)",
    HEIGHT: "Height (mm)",
    IMPORTED_FROM_CAR2DB: "Data imported from Car2db"
  },
  
  VEHICLE_TYPES: {
    TITLE_INDEX: "Vehicle Types",
    TITLE_EDIT: "Edit Vehicle Type",
    TITLE_ADD: "Add Vehicle Type",
    TITLE_ADD_1: "Add Type",
    SUBTITLE_DATA: "Vehicle Type",
    SUBTITLE_DIMENSIONS: "Dimensions",
    SEARCH: "Search type",    
    NAME: "Name",
    DESCRIPTION: "Descripción",
    SIZE: "Tamaño (mm)",
    LENGTH: "Longitud",
    WIDTH: "Anchura",
    HEIGHT: "Altura",
    MIN_MM: "Mín. (mm)",
    MAX_MM: "Máx. (mm)",
    INFO_IS_BASE: "Usado como base para el cálculo de tarifas"
  },
  
  FLEET: {
    TRUCKS: {
      TITLE_INDEX: "Trucks",
      TITLE_EDIT: "Edit Truck",
      TITLE_ADD: "Add Truck",
      SUBTITLE: "Truck data",
      SEARCH: "Search Trucks",
      TRUCK: "Truck",
      NICK: "Nick",
      PLATE: "Plate / Frame Number",
      TYPE: "Type",
      ERROR_409: "That Nick is already used by another of your Trucks",
      ERROR_412: "That Plate is already used by another of your Trucks"
    },
    CATEGORIES: {
      TITLE_INDEX: "Truck Categories",
      TITLE_EDIT: "Edit Category",
      TITLE_ADD: "Add Category",
      SUBTITLE_CAPACITY: "Capacity",
      SEARCH: "Search Category",
      CATEGORIES: "Categories",
      CATEGORY: "Category",
      CATEGORY_INFO: "Your Truck Category is defined by its capacity for the <strong>vehicle type {typeBasePosition} or {typeBaseName}</strong>. For example, a Truck with Category 8 can hold up to 8 {typeBaseName} units.",
      CAPACITY: "Capacity",
      TOTAL_CAPACITY: "Total Capacity (vehicles of Type {typeBasePosition})",
      CAPACITY_PER_TYPE: "Capacity per vehicle type",
      TYPE: "Type",
      COEFFICIENT: "Correction coefficient",
      COMPUTED_CAPACITY: "Truck Capacity",
      DEFAULT: "Default",
      DEFAULT_INFO: "Default Truck Category (this Category will be used as reference when setting the Fare prices)",
      INCREASE_OVER_DEFAULT: "% discount or surcharge over Fares compared to the Default Category",
      VOLUME_DISCOUNT: "Ajuste de las tarifas según nivel de ocupación",
      VOLUME_DISCOUNT_INFO: "Puedes fijar un descuento según las unidades a transportar de Tipo {typeBasePosition}. A partir de un total igual o superior a la carga completa, se ofertará el importe de Carga Completa.",
      VOLUME_DISCOUNT_HEADER_1: "Nº vehículos de Tipo {typeBasePosition}",
      VOLUME_DISCOUNT_HEADER_2: "Capacidad ocupada",
      VOLUME_DISCOUNT_HEADER_3: "Descuento por unidades",
      ERROR_409: "Esa capacidad ya está en uso por otra de tus categorías",
      ERROR_422: "Debes indicar la capacidad para cada uno de los tipos de vehículo",
      ERROR_424: "Debes indicar el descuento para cada uno de los niveles de ocupación"
    }
  },
  
  SERVICES: {
    TITLE_INDEX: "Extra services",
    TITLE_INDEX_INFO_1: "Servicios adicionales que el remitente puede elegir en el momento de la compra, ya sea con un coste o gratis.",
    TITLE_INDEX_INFO_2: "Ej.: lavar los vehículos, entregar en el dropoff del puerto...",
    TITLE_EDIT: "Edit Service",
    TITLE_ADD_1: "Add Service",
    TITLE_ADD_2: "Add Extra Service",
    SUBTITLE: "Service data",
    SEARCH: "Search Service",
    NAME: "Name",
    PROVINCE: "Province",
    PROVINCES: "Provinces",
    PRICE_PER_TYPE: "Price per Vehicle Type (€, taxes included)",
    TYPE: "Type"
  },
  
  FARES: {
    COMMON: {
      TITLE_ADD_1: "Add Fare",
      EXCEL_TITLE_1: "Import Excel",
      SEARCH: "Search Fare",
      FARE: "Fare",
      DISCOUNTS: "Discounts",
      SUBTITLE_DATA: "Fare data",
      ORIGIN: "Origin",
      DESTINATION: "Destination",
      ESTIMATED_DAYS: "Estimated time (days)",
      PRICE_PER_TRUCK: "Price full load (€, taxes included)",
      PRICE_PER_TRUCK_SHORT: "Price full load",
      PRICE_PER_TRUCK_INFO: "Introduce la tarifa que desees cobrar por carga completa, para la <strong><a href=\"/manager/trucks/categories\">categoría de camión</a></strong> que hayas marcado por defecto.",
      PRICE_PER_UNIT: "Precio spare unit (€, taxes included)",
      PRICE_PER_UNIT_SHORT: "Precio spare unit",
      PRICE_PER_UNIT_INFO: "Introduce la tarifa que desees cobrar cuando no se alcance la carga completa, por cada unidad de <strong>Tipo {typeBasePosition} o inferior</strong>.",
      SURCHARGE: "Surcharge",
      ALLOW_ONLY_PREMIUM: "Show this fare ony to Premium customers at the Comparator",
      PERCENT_INCREASE_NOT_PREMIUM: "Surcharge for Casual customers (%)",
      PERCENT_INCREASE_NOT_PREMIUM_INFO: "You can apply a surcharge percentage on the standard fare for those customers who do not have a payment plan."
    },
    STANDARD: {
      TITLE_INDEX: "Standard",
      TITLE_INDEX_INFO_1: "Enter your Standard Fares from one province to another, establishing a price per full load and optionally a surcharge per vehicle.",
      TITLE_INDEX_INFO_2: "You can add/edit/delete them manually one by one, or download an Excel with all the combinations, modify it and then upload it.",
      TITLE_EDIT: "Edit Standard Fare",
      TITLE_ADD_2: "Add Standard Fare",      
      ERROR_409: "There is already another Fare between that province pair",
      EXCEL: {
        TITLE_2: "Import Standard Fares from Excel file",
        INFO_GENERAL: "You can import Fare data for all provinces with our template.",
        DOWNLOAD: "Download template",
        DOWNLOAD_NAME: "LogiOK_Standard_Fares",
        UPLOAD: "Upload file",
        UPLOADED_OK: "File processed, see results below",
        RESULTS: "Results",
        CREATED_ROWS: "{count} created Fares",
        UPDATED_ROWS: "{count} updated Fares",
        ERROR_ROWS: "{count} wrong rows",
        ROW: "Row",
        CAUSE: "Cause",
        ERROR_400: "Wrong Excel file",
        ERROR_412: {
          1: "Missing Origin Province",
          2: "Missing Destination Province"
        },
        ERROR_424: "Wrong full load price",
        ERROR_428: "Wrong estimated days"
      }
    },
    TENDER: {
      TITLE_INDEX: "Tender",
      TITLE_INDEX_INFO_1: "Here you can see all the customers who have proposed you some Tender Routes.",
      TITLE_INDEX_INFO_2: "As with Standard Fares, you can set a price per full load and optionally a surcharge per vehicle.",
      TITLE_EDIT: "Edit Tender Fare",
      STATUS: "Estado",
      FARES_WAITING_PRICE: "No pending fares | 1 pending fare | {count} pending fares",
      TITLE_INDEX_FROM_SENDER: "Tarifas Tender"
    },
    P2P: {
      TITLE_INDEX: "P2P",
      TITLE_INDEX_INFO_1: "P2P Fares are meant for services that occur in nearby geographic areas, and whose pricing does not depend on high-capacity transports, but rather more punctual.",
      TITLE_INDEX_INFO_2: "You can establish several origin and destination postal codes, and choose between a price per travelled Km or a flat rate.",
      TITLE_EDIT: "Editar P2P Fare",
      TITLE_ADD_2: "Add P2P Fare" 
    }
  },

  OFFERS: {
    COMMON: {
      SEARCH: "Search Offers",
      EXCEL_TITLE_1: "Export to Excel",
      ID: "Id.",
      CREATION_DATE: "Creation Date",
      VEHICLES: "Vehicles",
      ORIGIN: "Origin",
      DESTINATION: "Destination",
      PATH: "Path",
      JUST_THERE: "Just There",
      THERE_AND_BACK: "There and Back",
      THERE: "There",
      BACK: "Back",
      TRANSPORTER: "Transporter",
      SENDER: "Sender",
      TRANSPORTER_NOTES: "Transporter Notes",
      SENDER_NOTES: "Sender Notes",
      FARE: "Fare",
      PRICE_TOTAL: "Total Price",
      TAXES_INCLUDED: "Taxes included",
      ORIGIN_DATE: "Fecha de recogida",
      ORIGIN_DATE_DESIRED: "Fecha de dispon.",
      ORIGIN_DATE_DESIRED_BACK_THERE: "Cuando reciba los vehículos",
      ORIGIN_DATE_PLANNED: "Fecha prevista de recogida",
      DESTINATION_DATE: "Fecha de entrega",
      DESTINATION_DATE_RANGE: "Plazo de entrega deseado",
      DESTINATION_DATE_TIME: "Horario de entrega",
      DESTINATION_DATE_DESIRED: "Fecha est. entrega",
      DESTINATION_DATE_PLANNED: "Fecha prevista de entrega",
      AVAILABILITY: "Disponibilidad",
      PLANNED: "Prevista",
      FLEXIBLE: "Flexible",
      STATUS: "Status",
      STATUS_AFTER_OFFER: "Accepted: in Shipments",
      REVIEW: "Review",
      OTHER_DATA: "Other data",
      OBSERVERS: "Observer/s",
      INVOICE_CENTER: "Invoice Center",
      SEND_COUNTEROFFER: "Send counteroffer",
      ACCEPT_OFFER: "Accept offer",
      ACCEPT_ALERT: "Are you sure to accept?",
      ACCEPT_OK: "Offer accepted",
      REJECT_OFFER: "Reject offer",
      REJECT_ALERT: "Are you sure to reject?",
      REJECT_OK: "Offer rejected",
      CANCEL_OFFER: "Cancel offer",
      CANCEL_ALERT: "Are you sure to cancel the offer?",
      CANCEL_OK: "Offer cancelled",
      MESSAGES: "Mensajes"
    },
    STANDARD: {
      TITLE_INDEX: "Transports",
      DOWNLOAD_NAME: "LogiOK_Offers",
      TITLE_EDIT: "Offer",
      STATUS_INFO: {
        1: {
         SENDER: "Se está tramitando el pago a través de LogiOK.",
         TRANSPORTER: "Se está tramitando el pago a través de LogiOK."
        },
        2: {
         SENDER: "El transportista te hará llegar los detalles para realizar el pago: número de cuenta, plazo...",
         TRANSPORTER: "Envía al remitente los detalles para realizar el pago: número de cuenta, plazo... Cuando recibas el dinero, acepta o rechaza. También puedes realizar los cambios que consideres oportunos y enviar una oferta revisada al remitente."
        },
        3: {
         SENDER: "El transportista ha recibido tu oferta y está a la espera de ser aceptada o rechazada.",
         TRANSPORTER: "Revisa los datos de la oferta antes de aceptar o rechazar el servicio. También puedes realizar los cambios que consideres oportunos y enviar una oferta revisada al remitente."
        },
        4: {
         SENDER: "El transportista ha estudiado tu oferta y ha decidido enviarte una contraoferta, con unas nuevas condiciones. Revísala y decide si quieres aceptarla, rechazarla o modificarla.",
         TRANSPORTER: "Se ha enviado tu contraoferta al remitente y está a la espera de ser aceptada o rechazada."
        },
        5: {
         SENDER: "Se ha enviado tu contraoferta al transportista y está a la espera de ser aceptada o rechazada.",
         TRANSPORTER: "El remitente ha estudiado tu oferta y ha decidido enviarte una contraoferta, con unas nuevas condiciones. Revísala y decide si quieres aceptarla, rechazarla o modificarla."
        },
        14: {
         SENDER: "Has cancelado esta petición de oferta.",
         TRANSPORTER: "El remitente ha decidido cancelar esta petición de oferta."
        }
      },
      ABSTRACT: "Resumen de la oferta",
      VEHICLES_DATA: "Vehicles data",
      ORIGIN_DATA: "Origin data",
      DESTINATION_DATA: "Destination data",
      ORIGIN_ADDRESS: "Origin Address",
      DESTINATION_ADDRESS: "Destination Address",
      ORIGIN_CONTACT: "Origin Contact",
      DESTINATION_CONTACT: "Destination Contact"
    },
    SPECIAL: {
      TITLE_INDEX: "Special Transp."
    },
    TENDER: {
      TITLE_INDEX: "Tender Queries"
    }
  },

  SHIPMENTS: {
    VIEW_SHIPMENT: "Ver envío",
    TITLE_INDEX: "Envíos",
    TITLE_EDIT: "Editar envío",
    SEARCH: "Buscar envío",
    ID: "Id. Envío",
    CREATION_DATE: "Fecha creación",
    VEHICLE: "Vehículo",
    ORIGIN: "Origen",
    DESTINATION: "Destino",
    PATH: "Trayecto",
    TRANSPORTER: "Transportista",
    DRIVER: "Chófer",
    SENDER: "Remitente",
    PRICE_AT_FINISH: "Precio final",
    COMMISSION_PERCENT: "% comisión",
    FINISHED_AT: "Fecha de finalización",
    TOTAL: "Total",
    TAXES_INCLUDED: "Taxes included",
    ORIGIN_DATE_DESIRED: "Fecha dispon.",
    ORIGIN_DATE_DONE: "Fecha de recogida realizada",
    DESTINATION_DATE: "Fecha est. entrega",
    DESTINATION_DATE_DONE: "Fecha de entrega realizada",
    STATUS: "Estado",
    WITH_INCIDENTS_1: "con inc.",
    WITH_INCIDENTS_2: "Con incidencias",
    EXCEL_TITLE: "Exportar Excel",
    DOWNLOAD_NAME: "LogiOK_Envios",
    CHECKED_ITEMS: "Sin items | 1 item seleccionado | {count} items seleccionados",
    PAYMENT_DATA: {
        TITLE: "Detalles del pago",
        EXTERNAL: "Por transferencia bancaria",
        CREDIT_CARD: "Tarjeta de crédito",
        CARD_NUMBER: "Número de tarjeta",
        AMOUNT: "Cantidad",
        DATE: "Payment date"
    },
    PLANNING: {
      TITLE: "Planificación",
      STATUS_INFO: {
        10: {
          SENDER: "El transportista ha recibido tu envío y será planificado en breve.",
          TRANSPORTER: "Revisa los datos del envío antes de realizar la entrega. También puedes gestionar el servicio asignando el chófer que desees, así como modificar las fechas de recogida y entrega."
        },
        11: {
          SENDER: "El transportista ha planificado tu envío y será enviado en breve.",
          TRANSPORTER: "Revisa los datos del envío antes de realizar la entrega. También puedes gestionar el servicio asignando el chófer que desees, así como modificar las fechas de recogida y entrega."
        },
        12: {
          SENDER: "El envío de este vehículo ha sido cancelado por una incidencia.",
          TRANSPORTER: "El envío de este vehículo ha sido cancelado por una incidencia."
        },
        13: {
          SENDER: "El vehículo ha sido entregado en destino.",
          TRANSPORTER:  "El vehículo ha sido entregado en destino."
        }
      },
      VEHICLE_COUNT: "1 Vehículo | {n} Vehículos",
      INVOICE_TO: "Facturar a",
      INVOICE_TO_ID: "CIF/NIF",
      VEHICLE_DATA: "Datos del vehículo",
      DONE: "Done"
    },
    DOCS: {
      TITLE: "Archivos",
      TITLE_2: "Archivos adjuntos",
      UPLOAD: "Subir archivo"
    },
    INCIDENTS: {
      TITLE: "Incidencias",
      TITLE_2: "Incidencias del envío",
      FINISHED: "Tramitada",
      UNFINISHED: "Sin tramitar",
      TITLE_ADD: "Reportar incidencia",
      TYPE: "Tipo",
      PRICE_INCREASE: "Incremento de precio",
      CANCEL_DATE: "Fecha de cancelación",
      NOTES: "Comentario",
      NOTES_INFO: "Introduzca un comentario sobre la incidencia",
      MARK_FINISHED: "Marcar incidencia como tramitada"
    }
  },
  
  CHATS: {
    FROM_MYSELF: "Me",
    AND_VEHICLES: " | and 1 more vehicle | and {count} more vehicles",
    TITLE: "Conversaciones",
    SEARCH: "Buscar conversaciones",
    SHIPMENT_ID: "ID. Envío",
    LATEST_MSG: "Últ. mensaje",
    FROM: "Enviado por",
    DATE: "Fecha"
  },
  
  INCIDENTS: {
      TITLE: "Incidencias no tramitadas",
      SEARCH: "Buscar incidencias",
      CREATED_AT: "Fecha inc.",
      TYPE: "Tipo inc.",
      TYPES: {
          TITLE: "Tipos de incidencia",
          SEARCH: "Buscar tipo de incidencia",
          SUBTITLE_EDIT: "Tipo de incidencia",
          NAME_1: "Nombre",
          NAME_2: "Nombre incidencia",
          CANCELS_1: "¿Cancela el envío?",
          CANCELS_2: "¿La incidencia cancela el envío?",
          TITLE_ADD_1: "Añadir tipo",
          TITLE_ADD_2: "Añadir tipo de incidencia",
          TITLE_EDIT: "Editar tipo de incidencia"
      }
  },
  
  SUBSCRIPTION_TYPES: {
      TITLE_INDEX: "Subscription Plans",
      TITLE_EDIT: "Subscription Plan",
      FREE: "Free",
      PER_MONTHS: "0 months | 1 month | {count} months",
      IS_DEFAULT: "Default",
      SUBTITLE_DATA: "Subscription Data",
      SUBTITLE_DESCRIPTION: "Subscription Features",
      NAME: "Name",
      PRICE_PER_MONTH: "Price (€/month)",
      PRICE_SENDER_DEFAULT_FREE: "Default Sender subscription must be free",
      MONTHS: "Renew each (months)",
      ADD_DESCRIPTION: "Add Feature",
      DESCRIPTION: "Feature"
  },

  HEADER: {
    TITLE: "LogiOK",
    SEARCH: "Search transportations",
    SENDER: "Do you own a company?",
    TRANSPORTER: "Are you a Transporter?",
    CONTACT: "Contact"
  },
  
  FOOTER: {
    CONTACT: "Contact",
    COMPANY: "Company",
    HOW_IT_WORKS: "How does it work?",
    TRANSPORTER: "Are you a Transporter?",
    CONTACT_US: "Contact us",
    FOLLOW_US: "Follow us",
    SECURE_PAYMENT: "Secure Payment",
    TERMS: "Terms & Conditions",
    PRIVACY_POLICY: "Privacy Policy",
    LEGAL_NOTE: "Legal note",
    COOKIES: "Cookies Policy",
    COOKIES_BANNER_1: "Esta página web utiliza únicamente las cookies necesarias para el correcto funcionamiento de tu cuenta.",
    COOKIES_BANNER_2: "Pulsa aquí para más información sobre cómo utilizamos las cookies.",
    COOKIES_BANNER_BUTTON: "I accept those cookies"
  },
  
  PRICE_SEARCH: {
    QUESTION: "¿Necesitas contratar un transporte de vehículos?",
    GO_TO_SEARCH_1: "Cotizador",
    GO_TO_SEARCH_2: "Ir al cotizador",
    STEP1: {
      TITLE: "Cotizador de servicios de transporte",
      ROUTE: "Ruta",
      ORIGIN: "Origin",
      ORIGIN_ADDRESS: "Origin Address",
      DESTINATION: "Destination",
      DESTINATION_ADDRESS: "Destination Address",
      IMPORT_FREQUENT_ADDRESS: "Importar dirección frecuente",
      IMPORT_TENDER_ROUTE: "Importar ruta tender",
      OUR_SERVICES: "Nuestros servicios",
      OWN_A_COMPANY: "¿Tienes una empresa?",
      JUST_THERE: "Solo ida",
      THERE_AND_BACK: "Ida y vuelta",
      THERE: "Ida",
      BACK: "Vuelta",
      SAME_VEHICLES: "Voy a enviar los mismos vehículos que en la ida",
      SAME_VEHICLES_2: "Los mismos que en la ida",
      VEHICLE: "Vehicle",
      VEHICLES: "Vehicles",
      VEHICLE_INFO: "Introduzca los datos de su vehículo",
      VEHICLE_ADD: "Añadir vehículo",
      VEHICLE_STATUS: "Selecciona el estado del vehículo",
      VEHICLE_STATUS_INFO: "En caso de tener más de un vehículo con distintos estados, necesitarás introducirlos en varias entradas",
      QUANTITY: "Cantidad",
      IMPORT_MY_VEHICLE: "Importar desde \"Mi Stock\"",
      DESTINATION_DATE_DESIRED: "Plazo de entrega deseado",
      ONE_WEEK: "Dentro de una semana",
      TWO_WEEKS: "Dentro de dos semanas",
      SPECIFIC_DATE: "En una fecha específica",
      SPECIFIC_RANGE: "Entre fechas específicas",
      FLEXIBLE: "Flexible",
      FLEXIBLE_CHEAPER: "Si escoges flexible, obtendrás los mejores precios del mercado. De lo contrario el transportista puede que aplique algún sobrecoste.",
      ERROR_409: "La dirección destino debe ser distinta al origen",
      ERROR_412: "Fechas incorrectas"
    },
    STEP2: {
      DELIVERY: "Entrega",
      DELIVERY_ONE_WEEK: "Entrega en una semana",
      DELIVERY_TWO_WEEKS: "Entrega en dos semanas",
      ESTIMATED_DELIVERY: "Entrega estimada",
      VEHICLE_COUNT: "1 Vehículo | {n} Vehículos",
      NEW_SEARCH: "Modificar búsqueda",
      OFFER_COUNT_0: "No hemos encontrado Ofertas", 
      OFFER_COUNT_1: "Hemos encontrado un total de",
      OFFER_COUNT_2: "0 Ofertas | 1 Oferta | {n} Ofertas",
      OFFER_COUNT_3: "para la ruta y vehículos seleccionados.",
      OFFER_COUNT_4: "Puedes ajustar tu búsqueda modificando los filtros.",
      OFFER_THERE_AND_BACK: "El precio y días estimados corresponden a la suma de la ida y de la vuelta.",
      ORDER_BY: "Ordenar por",
      LOW_PRICE: "Más baratas primero",
      HIGH_PRICE: "Más caras primero",
      LOW_DAYS: "Menos días primero",
      HIGH_DAYS: "Más días primero",
      HIGH_RATING: "Más valoradas primero",
      FARE: "Tarifa",
      TAXES_INCLUDED: "Impuestos incluidos",
      DAYS: "Días",
      DETAILS_SERVICE: "Condiciones del transportista",
      DETAILS_LOGIOK: "Condiciones de LogiOK",
      CONDITIONS_LOGIOK: [
        "El lugar de carga y descarga debe ser accesible legalmente para camiones porta vehículos, en caso contrario la empresa que haya contratado le ofrecerá alternativas.",
        "Las recogidas y entregas se realizan en las rutas que unen las grandes ciudades a nivel Nacional. En el caso que la población este fuera de las rutas estándar, será informado con antelación si conlleva algún sobre coste.",
        "El vehículo debe estar en disposición de ser cargado a partir de la fecha de disponibilidad de recogida que haya seleccionado.",
        "El plazo de recogida y entrega contratado es orientativo, actualizándose una vez realizado el pago del transporte, y estando sujeto dicho plazo a posibles modificaciones que le serán informadas por la empresa que haya contratado.",
        "En los casos de tramos marítimos o transporte internacional los plazos de recogida y entrega se detallarán según su caso.",
        "El vehículo no tendrá en su interior objetos o enseres personales en su interior, ni piezas sueltas del propio vehículo.",
        "En caso de anulación del traslado, una vez se ha iniciado el mismo, consulte con la empresa contratante las condiciones de anulación.",
        "Los vehículos deberán entregarse totalmente vacíos para su transporte; en caso de portar objetos en su interior, se transportará por cuenta y riesgo del propietario del vehículo.",
        "El vehículo en carga queda asegurado según condiciones CMR y L.O.T.T. ley 15/2009. Para vehículos de valor muy elevado consulte con la empresa contratante por si se debiera contratar un seguro adicional."
      ],
      TRANSPORTER_DATA: "Datos del transportista",
      RATING_COUNT: "1 Valoración | {n} Valoraciones",
      PURCHASE_NOW: "¡Contratar ahora!"
    },
    STEP3: {
      OFFER_ABSTRACT: "Resumen de la oferta",
      TOTAL: "Total",      
      EXTRA_DATA: {
        TITLE: "Contratación",
        ORIGIN_DATA: "Datos de origen",
        DESTINATION_DATA: "Datos de destino",
        CONTACT: "Contact",
        SAVE_ADDRESS: "Guardar dirección en mi listado de direcciones frecuentes",
        SAVE_ADDRESS_INFO: "Marca esta opción si deseas importar la dirección en tus próximas contrataciones",
        VEHICLES_DATA: "Datos de los vehículos",
        VEHICLES_DATA_INFO: "Necesitamos identificar los vehículos que vas a enviar por su matrícula o bastidor.",
        SAVE_VEHICLES: "Guardar vehículos en \"Mi Stock\"",
        SAVE_VEHICLES_INFO: "Marca esta opción si deseas importar los datos de estos vehículos en tus próximas contrataciones",
        PLATE_REPEATED: "Repeated Plate",
        DATES_DATA: "Fechas del envío",
        ORIGIN_DATE_DESIRED: "Fecha de disponibilidad de recogida",
        ORIGIN_DATE_DESIRED_INFO: "Fecha a partir de la cual el vehículo debe estar en disposición de ser cargado en el lugar de origen",
        ORIGIN_DATE_DESIRED_BACK: "Fecha de disponibilidad para la vuelta",
        ORIGIN_DATE_DESIRED_BACK_THERE: "Cuando reciba los vehículos",
        DESTINATION_DATE_ESTIMATED: "Fecha estimada de entrega",
        DESTINATION_DATE_ESTIMATED_INFO: "Fechas y horarios de entrega orientativos, el transportista tratará de adaptarse al plazo escogido en función de su disponibilidad",
        DESTINATION_DATE_DESIRED_TIME: "Horario de entrega",
        ALL_DAY: "Durante todo el día",
        MORNING: "Mañana",
        AFTERNOON: "Tarde",
        NOTES: "Observaciones",
        NOTES_INFO: "Introduzca las observaciones sobre su envío",
        SERVICES: "Servicios adicionales",
        SERVICES_INFO: "El transportista ofrece los siguientes servicios adicionales para tu envío",
        SERVICES_ORIGIN: "Servicios en origen",
        SERVICES_ORIGIN_NONE: "No se han encontrado servicios adicionales para este origen",
        SERVICES_DESTINATION: "Servicios en destino",
        SERVICES_DESTINATION_NONE: "No se han encontrado servicios adicionales para este destino"
      },
      REGISTER_DATA: {
        TITLE: "Registro",
        TITLE_2: "¿Aún no eres miembro de LogiOK?",
        COPY: "Completar con los datos de origen del envío. Modifica los datos importados que no sean correctos."
      },
      FINISH: {
        TITLE: "Finalizar y pagar",  
        OTHER_DATA: "Otros datos",
        OBSERVERS: "Observador/es",
        OBSERVERS_INFO: "Da de alta a alguien que quieres que esté informado de este transporte",
        INVOICE_CENTER: "Centro de facturación",
        INVOICE_CENTER_INFO: "Seleccionar centro",
        PAYMENT_METHOD: "Elige un método de pago",
        PAYMENT_INTERNAL: "Tarjeta de crédito y débito",
        PAYMENT_INTERNAL_INFO_1: "Se abrirá una nueva ventana de la pasarela de pago.",
        PAYMENT_INTERNAL_INFO_2: "Pago online procesado de forma 100% segura por Multisafepay.",
        PAYMENT_INTERNAL_INFO_3: "Los datos de tu tarjeta no serán guardados en nuestros servidores.",
        PAYMENT_EXTERNAL: "Transferencia bancaria",
        PAYMENT_EXTERNAL_INFO: "El transportista te indicará las condiciones: número de cuenta, plazo...",
        CONFIRM_PAYMENT: "Confirmar pago",
        ERROR_402: "No se puede conectar con la pasarela de pago",
        ERROR_406: "No se encuentran los datos de la búsqueda",
        ERROR_410: "No se encuentran los datos de la ruta",
        ERROR_418: "No se encuentran los datos de la oferta",
        ERROR_409: "La dirección destino debe ser distinta a la origen",
        ERROR_411: "Faltan datos de los vehículos de la ida",
        ERROR_415: "Faltan datos de los vehículos de la vuelta"
      }
    },
    PURCHASE_OK: {
      THANK_YOU: "¡Gracias por tu contratación!",
      THANK_YOU_INFO_1: "Te hemos enviado un email con la confirmación.",
      THANK_YOU_INFO_2: "Tu envío está siendo procesado y será gestionado por el transportista lo antes posible.",
      TRANSPORTER: "Transportista",
      OFFER_ABSTRACT: "Resumen de la oferta"
    },
    PURCHASE_KO: {
      TITLE: "Compra cancelada"
    }
  },
  
  LANDING: {
    INDEX: {
      BLOCK1: {
        TITLE: "Encuentra un transporte para tu vehículo, al instante",
        PARAGRAPH: "LogiOK es un comparador online gratuito, donde sin intermediarios y en una sola búsqueda, te ayudaremos a conseguir las mejores ofertas de todas las empresas de transporte del mercado.",
        BUTTON: "Buscar"
      },
      BLOCK2: {
        TITLE: "Nuestros servicios",
        PARAGRAPH: "Con LogiOK podrás realizar cualquier tipo de envío. Si necesitas un transporte especial, contacta con nosotros y te ayudaremos a encontrar una oferta a medida para ti.",
        SHIP: "Barco",
        CONFIDENTIAL: "Camión confidencial",
        GROUP: "Camión grupaje",
        OPEN: "Grúa express abierta",
        DRIVER: "Driver",
        MOTORCYCLES: "Motos",
        HEAVY: "Pesados",
        HIGH: "Furgones  + 3,20m altura",
        BUTTON: "Quiero un transporte especial"
      },
      BLOCK3: {
        TITLE: "¿Cómo funciona?",
        PARAGRAPH1: "Es muy fácil: Introduce tu solicitud de transporte y te ayudaremos a encontrar las mejores ofertas.",
        PARAGRAPH2: "LogiOK te permite conectar con nuestros transportistas, previamente verificados."
      },
      BLOCK4: {
        STEP1: {
          TAG: "1. Búsqueda",
          TITLE: "Completa tu solicitud de transporte", 
          PARAGRAPH1: "Comienza introduciendo qué vehículo deseas transportar, dónde y en qué plazo lo necesitas. Te mostraremos las ofertas de los transportistas disponibles para la ruta y fechas elegidas.",
          PARAGRAPH2: "Rellenar nuestro cotizador te llevará menos de un minuto.",
          PARAGRAPH3: "Si tienes una empresa, puedes agilizar este proceso accediendo a tus direcciones y vehículos frecuentes.",
          PARAGRAPH4: "Puedes contratar envíos de ida y vuelta, así como de múltiples destinos."
        },
        STEP2: {
          TAG: "2. Contratación",
          TITLE: "Contrata un servicio de transporte", 
          PARAGRAPH1: "Revisa y compara entre todas las ofertas proporcionadas por nuestros transportistas.",
          PARAGRAPH2: "Ofertas completas con toda la información que necesites: plazo de entrega, precio total, condiciones, valoraciones del transportista...",
          PARAGRAPH3: "Todos nuestros transportistas están verificados."
        },
        STEP3: {
          TAG: "3. Seguimiento",
          TITLE: "Sigue el estado de tu envío en todo momento", 
          PARAGRAPH1: "¡No te pierdas ningún paso durante el envío de tu vehículo!",
          PARAGRAPH2: "Comunicación directa con el transportista mediante nuestro chat.",
          PARAGRAPH3: "Seguimiento de los estados del envío para conocer todas las novedades durante el transporte.",
          PARAGRAPH4: "Pago seguro y reembolsable."
        }
      },
      BLOCK5: {
        TITLE: "¿Por qué elegir LogiOK?",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        STEP1: {
          TITLE: "Flexibilidad absoluta", 
          PARAGRAPH: "Contamos con un equipo experto en el transporte de vehículos que nos permite ofrecerte un servicio completo. Nuestra logística se adapta a las necesidades de cada cliente. Desde el vehículo (cualquier tipo y estado), a la ruta y plazos que necesites."
        },
        STEP2: {
          TITLE: "Seguro y al mejor precio", 
          PARAGRAPH: "Todos nuestros transportistas están verificados. Además, puedes consultar las valoraciones de otros clientes. Además, al estar haciendo viajes similares, sus ofertas de presupuestos son más baratas que los precios habituales."
        },
        STEP3: {
          TITLE: "Cumplimos los plazos", 
          PARAGRAPH: "Podrás conocer en cada momento dónde se encuentra tu vehículo durante su transporte. Te informamos de las fechas de entrega y recogida, además de las incidencias que puedan surgir, aportando soluciones."
        },
        STEP4: {
          TITLE: "Comunicación y soporte", 
          PARAGRAPH: "Siempre en contacto con el cliente para responder tus consultas, mostrarte el estado de tu transporte y ofrecerte las soluciones que necesites. Podrás consultar online el estado de tu envío con nuestra plataforma para clientes."
        }
      }
    },
    SENDER: {
      BLOCK1: {
        PRETITLE: "LogiOK Empresas",
        TITLE: "La mejor solución de transporte para tu empresa",
        PARAGRAPH: "En LogiOK conseguirás los mejores precios del mercado, sin intermediarios, ni comisiones. Consultas ruta, seleccionas modelo y unidades, y contratas directamente a la empresa de transporte que más se adapte a tus necesidades.",
        BUTTON: "Regístrate en LogiOK"
      },
      BLOCK2: {
        TITLE: "Busca, compara y contrata",
        STEP1: {
          TITLE: "Flexibilidad total",
          PARAGRAPH: "Nos adaptamos a tus necesidades en cada transporte: envío múltiple de vehículos de cualquier tipo y estado, en las fechas que nos lo indiques, a varios destinos..."
        },
        STEP2: {
          TITLE: "Al instante, sin intermediarios",
          PARAGRAPH: "Conecta directamente con multitud de empresas de transporte. Solo tienes que buscar la ruta que necesites realizar para descubrir todas las ofertas disponibles."
        },
        STEP3: {
          TITLE: "Optimizada para tu empresa",
          PARAGRAPH: "LogiOk te ofrece una solución adaptada para tu negocio: gestiona tu flota de vehículos, direcciones y clientes habituales, centros de facturación..."
        }
      },
      BLOCK3: {
        TITLE: "Funciones para empresas",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        FEATURE1: "Gestión de los usuarios de tu empresa",
        FEATURE2: "Trabaja con tus direcciones y vehículos frecuentes",
        FEATURE3: "Gestiona tus centros de facturación",
        FEATURE4: "Envíos multivehículo y multiruta",
        FEATURE5: "Busca y compara entre multitud de ofertas",
        FEATURE6: "Escoge los plazos de entrega deseados",
        FEATURE7: "Descuentos temporales y para clientes habituales",
        FEATURE8: "Valoraciones de otros clientes",
        FEATURE9: "Seguimiento del transporte",
        FEATURE10: "Sistema de incidencias",
        FEATURE11: "Comunicación directa con transportistas vía chat"
      },
      BLOCK4: {
        TITLE: "Planes de subscripción",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        BUTTON: "¡Me interesa!"
      }
    },
    TRANSPORTER: {
      BLOCK1: {
        PRETITLE: "LogiOK Transportistas",
        TITLE: "Recibe nuevos trabajos de transporte cada día",
        PARAGRAPH: "Te ofrecemos soluciones digitales inteligentes, para que la relación con tus clientes sea eficaz, segura y transparente.",
        BUTTON: "Regístrate como Transportista"
      },
      BLOCK2: {
        TITLE: "¿Cómo funciona?",
        BUTTON: "Comenzar",
        STEP1: {
          TAG: "1. Configura tu empresa",
          TITLE: "Configura tu empresa", 
          PARAGRAPH1: "Comienza definiendo los recursos de tu empresa, como la flota de camiones y remolques, tus conductores o los servicios extra que ofreces en el transporte (p.ej. limpieza del vehículo).",
          PARAGRAPH2: "Nuestro sistema de categorías de remolques te permitirá definir de manera fácil y precisa su capacidad.",
          PARAGRAPH3: "Podrás asociar un conductor un camión para que se le asigne automáticamente cuando recibas un servicio."
        },
        STEP2: {
          TAG: "2. Publica tus tarifas",
          TITLE: "Publica tus tarifas", 
          PARAGRAPH1: "Establece cuáles son las tarifas de las rutas que realices para recibir nuevos trabajos de transporte.",
          PARAGRAPH2: "Sólo tienes que introducir el origen, destino y precio por carga completa. Nuestro sistema calculará de manera automática el precio a ofertar según el nivel de ocupación del camión en cada transporte.",
          PARAGRAPH3: "3 tipos de tarifas: Estándar, con direcciones de clientes (Tender) o para servicios de cercanía (P2P).",
          PARAGRAPH4: "Podrás agilizar este proceso importando varias tarifas de manera conjunta desde un archivo Excel."
        },
        STEP3: {
          TAG: "3. Realiza el transporte",
          TITLE: "Realiza el transporte", 
          PARAGRAPH1: "Cuando un cliente contrate un transporte, recibirás un aviso con la oferta detallada.",
          PARAGRAPH2: "Podrás decidir si aceptas el servicio o no, o enviar una contraoferta, modificando el precio o las fechas.",
          PARAGRAPH3: "Seguimiento del estado del envío (App móvil para conductores) y gestor de incidencias para visualizar y gestionar aquellas que se reporten durante el transporte.",
          PARAGRAPH4: "Comunicación directa con el cliente mediante chat.."
        }
      },
      BLOCK3: {
        TITLE: "Funciones para transportistas",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        FEATURE1: "Gestión de los usuarios de tu empresa",
        FEATURE2: "Cálculo automático de las ofertas según ocupación",
        FEATURE3: "Tarifas específicas para clientes habituales",
        FEATURE4: "Tarifas de transporte en cercanías",
        FEATURE5: "Publicación de descuentos temporales",
        FEATURE6: "Gestión de las fechas de entrega y recogida",
        FEATURE7: "Comunicación directa con clientes vía chat",
        FEATURE8: "Asignación automática de conductor por servicio",
        FEATURE9: "Seguimiento del transporte",
        FEATURE10: "App móvil para conductores",
        FEATURE11: "Gestión de incidencias",
        FEATURE12: "Valoraciones de clientes"
      },
      BLOCK4: {
        TITLE: "Planes de subscripción",
        PARAGRAPH: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt. Maecenas tincidunt, velit ac porttitor pulvinar, tortor eros facilisis libero.",
        BUTTON: "¡Me interesa!"
      }
    },
    PRICE_SEARCH: {
      BLOCK1: {
        STEP1: {
          TITLE: "¿Cuánto tardará la entrega?",
          PARAGRAPH: "Podrás escoger entre distintos plazos de entrega para tu vehículo, o, si lo deseas, marcar una fecha específica."
        },
        STEP2: {
          TITLE: "¿Puedo enviar cualquier vehículo?",
          PARAGRAPH: "Sí, nuestra plataforma te permitirá enviar vehículos de cualquier marca y modelo, sea cual sea su estado."
        },
        STEP3: {
          TITLE: "¿Mi vehículo está asegurado?",
          PARAGRAPH: "Sí, tu vehículo está asegurado contra todos los riesgos que puedan surgir durante el transporte."
        }
      },
      BLOCK2: {
        TITLE: "Nuestros clientes"
      }
    },
    CONTACT: {
      TITLE: "Contacto",
      CALL: "Llámanos",
      WRITE: "Envíanos un correo",
      WHERE: "Estamos en...",
      WHEN: "Nuestro horario",
      DAYS: "Lunes a Viernes",
      FORM: {
        TITLE: "¿Tienes alguna duda?",
        PARAGRAPH: "Completa el formulario y un representante de LogiOK te responderá a la mayor brevedad posible.",
        FULLNAME: "Nombre y apellidos*",
        PHONE: "Teléfono*",
        EMAIL: "Email*",
        REFERENCE: "Referencia de envío",
        MESSAGE: "Mensaje*",
        SEND: "Enviar",
        SEND_OK_1: "Mensaje enviado correctamente",
        SEND_OK_2: "Te responderemos lo antes posible",
        SEND_KO: "Error al enviar"
      }
    }
  }
};
