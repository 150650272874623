const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  let value = window.localStorage.getItem(ID_TOKEN_KEY);
  return !!value && (typeof value === "string" || value instanceof String)
    ? value
    : null;
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
