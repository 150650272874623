import ApiService from "@/core/services/api/v2/api.service";
import store from "@/core/services/store";

const USER_FILTER_KEY = "UserFilter";
const DRIVER_FILTER_KEY = "DriverFilter";
const OBSERVER_LIST_KEY = "ObserverList";

const UserService = {
  checkUserActive() {
    return new Promise(resolve => {
      ApiService.get("checkUserActive")
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getCurrentUserProfile() {
    return new Promise(resolve => {
      ApiService.get("user")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateCurrentUserProfile(newData) {
    return new Promise(resolve => {
      // Special append of each field, for new Avatar photo
      const formData = new FormData();
      Object.keys(newData).forEach(k => {
        if (newData[k]) {
          formData.append(k, newData[k]);
        }
      });

      ApiService.post("user", formData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getSomeUserProfile(userID) {
    return new Promise(resolve => {
      ApiService.get("users/" + userID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateSomeUserProfile(userID, newData) {
    return new Promise(resolve => {
      ApiService.put("users/" + userID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getAdminProfileMyCompany() {
    return new Promise(resolve => {
      ApiService.get("companyAdmin")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getAdminProfileByCompany(companyID) {
    return new Promise(resolve => {
      ApiService.get("companyAdmin/" + companyID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateAdminProfileByCompany(companyID, newData) {
    return new Promise(resolve => {
      ApiService.put("companyAdmin/" + companyID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updatePasswordViaToken(newData) {
    return new Promise(resolve => {
      ApiService.put("resetPassword", newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  searchUsers(searchText, filterByRoleID, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByRoleID = parseInt(filterByRoleID);
      if (!isNaN(filterByRoleID) && filterByRoleID > 0) {
        queryParams.RoleID = filterByRoleID;
      }

      window.sessionStorage.setItem(
        USER_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("users", { params: queryParams })
        .then(response => {
          // Return full response, not just "Users" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFiltersUsers() {
    let cachedFilters = window.sessionStorage.getItem(USER_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  listMyStaffAndMe(orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // A SenderAdmin searches his SenderEmployees,
      // a TransporterAdmin his TransporterDrivers
      let queryUrl = "";
      switch (parseInt(store.getters.currentUserRoleID)) {
        case store.getters.rolesList.ID_SENDER_ADMIN: {
          queryUrl = "employee";
          break;
        }
        case store.getters.rolesList.ID_TRANSPORTER_ADMIN: {
          queryUrl = "driver";
          break;
        }
      }

      if (queryUrl === "") {
        resolve(404);
      } else {
        // Parse params
        const queryParams = { MeToo: 1 };

        orderBy = orderBy ? orderBy.trim() : "";
        if (orderBy.length > 0) {
          queryParams.OrderBy = orderBy;
        }

        queryParams.OrderDesc = orderDesc ? 1 : 0;

        limit = parseInt(limit);
        if (!isNaN(limit) && limit > 0) {
          queryParams.Limit = limit;
        }

        offset = parseInt(offset);
        if (!isNaN(offset)) {
          queryParams.Offset = offset;
        }

        ApiService.query(queryUrl, { params: queryParams })
          .then(response => {
            // Return full response, not just "Users" array, for pagination etc
            resolve(response.data);
          })
          .catch(() => {
            resolve(404);
          });
      }
    });
  },

  deleteStaff(userID) {
    return new Promise(resolve => {
      // A SenderAdmin can delete one of his SenderEmployees,
      // a TransporterAdmin one of his TransporterDrivers
      let deleteUrl = "";
      switch (parseInt(store.getters.currentUserRoleID)) {
        case store.getters.rolesList.ID_SENDER_ADMIN: {
          deleteUrl = "employee/";
          break;
        }
        case store.getters.rolesList.ID_TRANSPORTER_ADMIN: {
          deleteUrl = "driver/";
          break;
        }
      }

      if (deleteUrl === "") {
        resolve(404);
      } else {
        ApiService.delete(deleteUrl + userID)
          .then(response => {
            resolve(response.status);
          })
          .catch(error => {
            resolve(error.response.status);
          });
      }
    });
  },

  searchDrivers(
    searchText,
    filterByTruckID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByTruckID = parseInt(filterByTruckID);
      if (!isNaN(filterByTruckID) && filterByTruckID !== 0) {
        queryParams.TruckID = filterByTruckID;
      }

      window.sessionStorage.setItem(
        DRIVER_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("driver", { params: queryParams })
        .then(response => {
          // Return full response, not just "Users" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFiltersDrivers() {
    let cachedFilters = window.sessionStorage.getItem(DRIVER_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  getDriverProfile(userID) {
    return new Promise(resolve => {
      ApiService.get("driver/" + userID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateDriverProfile(userID, newData) {
    return new Promise(resolve => {
      ApiService.put("driver/" + userID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createDriverProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("driver", newData)
        .then(response => {
          // Response (if correct) holds the new UserID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getEmployeeProfile(userID) {
    return new Promise(resolve => {
      ApiService.get("employee/" + userID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateEmployeeProfile(userID, newData) {
    return new Promise(resolve => {
      ApiService.put("employee/" + userID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createEmployeeProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("employee", newData)
        .then(response => {
          // Response (if correct) holds the new UserID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  searchObservers(searchText, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      ApiService.query("observer", { params: queryParams })
        .then(response => {
          // Return full response, not just "Users" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getObserverProfile(userID) {
    return new Promise(resolve => {
      ApiService.get("observer/" + userID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateObserverProfile(userID, newData) {
    return new Promise(resolve => {
      ApiService.put("observer/" + userID, newData)
        .then(response => {
          this.cleanObserversCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createObserverProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("observer", newData)
        .then(response => {
          this.cleanObserversCachedList();
          // Response (if correct) holds the new UserID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteObserverProfile(userID) {
    return new Promise(resolve => {
      ApiService.delete("observer/" + userID)
        .then(response => {
          this.cleanObserversCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  listAllObservers(avoidCache) {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(OBSERVER_LIST_KEY);
      if (cachedList && !avoidCache) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.query("observer", { params: { Limit: 999 } })
          .then(response => {
            let newList = response.data.response.Users;

            window.sessionStorage.setItem(
              OBSERVER_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanObserversCachedList() {
    window.sessionStorage.removeItem(OBSERVER_LIST_KEY);
  }
};

export default UserService;
