import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import i18nService from "@/core/services/i18n.service.js";

/**
 * Service to call LogiOK API via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_HOST + "/api/v2/";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = JwtService.getToken();
    Vue.axios.defaults.headers.common["Content-Type"] = "application/json";
    Vue.axios.defaults.headers.common["Lang"] = i18nService.getActiveLanguage();
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    this.setHeader();
    const getUrl = slug ? resource + "/" + slug : resource;
    return Vue.axios.get(getUrl);
  },

  /**
   * Send the GET HTTP request for a raw blob resource
   * @param resource
   * @returns {*}
   */
  getBlob(resource) {
    this.setHeader();
    return Vue.axios.get(resource, {
      responseType: "blob"
    });
  },

  /**
   * Send the GET with querystring params HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request for a raw blob resource
   * @param resource
   * @returns {*}
   */
  queryBlob(resource, params) {
    this.setHeader();
    params.responseType = "blob";
    return Vue.axios.get(resource, params);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeader();
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return Vue.axios.delete(resource);
  }
};

export default ApiService;
