import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api/v2/api.service";
import { VERIFY_AUTH, UNDO_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// API service init
ApiService.init();

// Ensure we checked auth before each private page load
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
      // If VERIFY_AUTH fails, cleans auth; then LayoutManager.vue->updated() will redirect to Landing

      // Extra check for restricted-by-SubscriptionPremium routes
      if (to.matched.some(record => record.meta.requiresPremiumSubscription)) {
        if (!store.getters.currentUserCompanyIsPremium) {
          store.dispatch(UNDO_AUTH);
        }
      }

      // Extra check for restricted-by-Role routes
      if (to.matched.some(record => record.meta.requiresRoleID)) {
        let myRole = store.getters.currentUserRoleID;
        let requiredRoles = to.meta.requiresRoleID;
        if (!requiredRoles.includes(myRole)) {
          store.dispatch(UNDO_AUTH);
        }
      }

      next();
    });
  } else {
    next();
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
